import React, { useState } from 'react';
import { Card, Stack, CardBody, Text, Button, Box, FormControl, FormLabel, Input, CardFooter } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginAsync } from '../../../redux/reducers/authReducer';
import toast from 'react-hot-toast';

const Login = () => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector(state => state.auth);
  const [credentials, setCredentials] = useState({ customerId: '', password: '' });
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const resultAction = await dispatch(loginAsync(credentials));
      const { payload } = resultAction;

      if (loginAsync.fulfilled.match(resultAction)) {
        console.log('Logged in successfully:', payload);

        // Store token and customerId in localStorage for persistence
        localStorage.setItem('customerId', payload.customerId);
        localStorage.setItem('token', payload.token);

        navigate('/table-status');
        window.location.reload('/table-status');
      }
    } catch (error) {
      console.error('Failed to login:', error);
      toast.error('Login failed!');
    }
  };

  return (
    <Box display='flex' alignItems="center" justifyContent="center" height="100vh" bg='#FFEFD5'>
      <Card
        overflow="hidden"
        variant="outline"
        width={{ base: '90%', md: '50%', lg: '35%' }} // Adjust card width based on screen size
        p={6}
        bg='#FEA116'
        boxShadow="md"
      >
        <Stack spacing={6} alignItems="center">
          <CardBody>
            <FormControl isRequired>
              <Text fontSize="3xl" as="b" textAlign="center" display='flex' justifyContent='center' alignItems='center' mb={4}>Login</Text>

              <Box mb={4}>
                <FormLabel color="black">CustomerId</FormLabel>
                <Input
                  bg="#FAFAFA"
                  placeholder="Enter your CustomerId"
                  value={credentials.customerId}
                  onChange={(e) => setCredentials({ ...credentials, customerId: e.target.value })}
                />
              </Box>

              <Box mb={4}>
                <FormLabel color="black">Password</FormLabel>
                <Input
                  type="password"
                  bg="#FAFAFA"
                  placeholder="Enter your password"
                  value={credentials.password}
                  onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
                />
              </Box>

              <Button
                width="100%" // Full width button
                variant="solid"
                color='#FEA116'
                bg='#0F172B'
                _hover={{ color: 'white' }}
                onClick={handleLogin}
                isLoading={loading} // Show loading state during login process
              >
                Login
              </Button>
            </FormControl>
          </CardBody>

          <CardFooter>
            <Text cursor='pointer' onClick={() => navigate('/generate-otp')}>
              <b>Forgot Password?</b>
            </Text>
          </CardFooter>
        </Stack>
      </Card>
    </Box>
  );
};

export default Login;
