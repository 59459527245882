// TableTimeContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

// Create context
const TableTimeContext = createContext();

// Create provider component
export const TableTimeProvider = ({ children }) => {
  const [startTime, setStartTime] = useState(null); // The time when the button is pressed
  const [elapsedTime, setElapsedTime] = useState(0); // Elapsed time in minutes

  // Function to capture the current time when the button is pressed
  const startTimer = () => {
    setStartTime(new Date());
  };
  const resetTimer = () => {
    setStartTime(null);
    setElapsedTime(0);
  };

  // Update elapsed time every minute
  useEffect(() => {
    if (startTime) {
      const intervalId = setInterval(() => {
        const now = new Date();
        const difference = Math.floor((now - startTime) / 60000); // Convert to minutes
        setElapsedTime(difference);
      }, 60000); // Update every minute

      return () => clearInterval(intervalId); // Cleanup on unmount or when startTime changes
    }
  }, [startTime]);

  return (
    <TableTimeContext.Provider value={{ elapsedTime, startTimer,resetTimer }}>
      {children}
    </TableTimeContext.Provider>
  );
};

// Custom hook for using the context
export const useTableTime = () => {
  return useContext(TableTimeContext);
};
