import axios from 'axios';
import toast from 'react-hot-toast';
import { useKotContext } from '../context/kotContext';
import { useEffect, useState } from 'react';

const UpdateKotPrint = (arr1, tableNo, totalAmount, printerName) => {
  const { kotNumber, serverName } = useKotContext();
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');

  const customerId = localStorage.getItem('customerId');

  function getFormattedCurrentDate() {
    const now = new Date(); // Get the current date and time
    
    // Format the date as "DD-MM-YYYY"
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // getMonth() is 0-based, so +1
    const year = now.getFullYear();
    
    // Format the time as "HH:MM AM/PM"
    let hours = now.getHours();
    let minutes = String(now.getMinutes()).padStart(2, '0'); // Add leading zero to minutes if needed
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert 24-hour time to 12-hour time, adjusting midnight to 12
    
    const formattedDate = `${day}-${month}-${year}`;
    const formattedTime = `${hours}:${minutes}${period.toLowerCase()}`;
    
    return [formattedDate, formattedTime];
  }

  // useEffect to update date and time when component mounts or customerId/tableNo changes
  useEffect(() => {
    const [formattedDate, formattedTime] = getFormattedCurrentDate();
    setDate(formattedDate);
    setTime(formattedTime);
  }, [customerId, tableNo]); // Add dependencies

  // Function to update and print KOT
  const printKot = async () => {
    try {
      const response = await axios.patch('https://api.rcb.tdrsoftware.in/api/kotBillUpdate', {
        customerId: customerId,
        kotNo: kotNumber,
        data: {
          foodItem: arr1,
        },
        serverName: serverName,
      });

      toast.success(response.data.message);
      console.log(response.data.updatedFoodItems);

      const data = response.data.updatedFoodItems || []; // Ensure updatedFoodItems is an array

      // Prepare print data with CSS for 3-inch page size
      const printData = `
        <style>
          @page {
            size: 3in auto; /* Set page width to 3 inches */
            margin: 0;
            margin-bottom:10 /* Remove margins for accurate sizing */
          }
          body {
            width: 3in; /* Page width of 3 inches */
            margin: 0;
            margin-bottom: 20px;
            font-size: 12px;
          }
          h2 {
            font-size: 14px;
            margin-bottom: 10px;
          }
          p {
            margin: 5px 0;
            font-size: 12px;
          }
          table {
            width: 100%;
            border-collapse: collapse;
            font-size: 12px;
          }
          td {
            padding: 5px;
            border: 1px solid #000;
          }
          /* Make the name column larger and quantity smaller */
          td.name {
            width: 80%; /* Adjust this width as needed */
          }
          td.quantity {
            width: 20%; /* Adjust this width as needed */
          }
        </style>
        <body>
          <h2>KOT Details:</h2>
          <p>Table No.: ${tableNo}</p>
          <p>KOT No.: ${kotNumber}</p>
          <p>Server: ${serverName}</p>
          <p>Date: ${date}</p>
          <p>Time: ${time}</p>
          <p>Food Items:</p>
          <table>
            <thead>
              <tr>
                <td class="name">Name</td>
                <td class="quantity">Quantity</td>
                
              </tr>
            </thead>
            <tbody>
              ${Array.isArray(data) ? data.map((item) => `
                <tr key="${item._id}">
                  <td class="name">${item.name}-(${item.note?item.note:""})</td>
                  <td class="quantity">${item.newQuantity - item.oldQuantity}</td>
                 
                </tr>
              `).join('') : ''}
            </tbody>
          </table>
        </body>
      `;

      // Create a hidden iframe for printing
      const iframe = document.createElement('iframe');
      iframe.style.position = 'fixed';
      iframe.style.top = '-9999px'; // Hide the iframe offscreen
      document.body.appendChild(iframe);

      // Write content to iframe
      const iframeDoc = iframe.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(printData);
      iframeDoc.close();

      // Trigger print dialog
      iframe.contentWindow.focus();
      iframe.contentWindow.print();

      // Remove iframe after printing
      setTimeout(() => {
        document.body.removeChild(iframe);
      }, 1000);

    } catch (error) {
      console.error('Error saving bill:', error);
      toast.error('Error in Kot');
    }
  };

  return printKot;
  // Return the function to be used in a button click
};

export default UpdateKotPrint;
