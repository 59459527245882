import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Select,
    Box,
    Flex,Image,
    Text,Button,Card,CardBody,SimpleGrid,
    Textarea
  } from "@chakra-ui/react";
  import axios from 'axios';
  import {useSelector} from 'react-redux'
  
  import "react-calendar/dist/Calendar.css";
  import Calendar from "react-calendar";
  import React, { useState,useEffect } from "react";

  
   
    
  import toast from "react-hot-toast";
   
    
   
    
  
    const CategoryRegister = () => {
      const customerId=localStorage.getItem('customerId')
    console.log(customerId);
    const [roomData,setRoomData]=useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    const[imageURL,setImageURL]=useState(null);
    const [uploading, setUploading] = useState(false);
    const [printData,setPrintData]=useState([])
    const [images,setImages]=useState([])
    const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0]);
    };
  
  
    // const handleUpload = async () => {
    //   if (!selectedFile) return;
  
    //   setUploading(true);
    //   const params = {
    //     Bucket: 'tdr-hotel',
    //     Key: selectedFile.tableNo,
    //     Body: selectedFile,
    //     ContentType: selectedFile.type,
    //   };
  
    //   try {
    //     const upload = new Upload({
    //       client: s3Client,
    //       params: params,
    //     });
  
    //     upload.on("httpUploadProgress", (progress) => {
    //       console.log(progress);
    //     });
  
    //     await upload.done();
    //     console.log("Upload successful!");
  
    //     const response = await axios.post("https://api.rcb.tdrsoftware.in/get-signed-url", { key: selectedFile.tableNo });
    //     const signedUrl = response.data.url;
        
    //     setImageURL(signedUrl);
    //   } catch (err) {
    //     console.error("Upload failed:", err);
    //   } finally {
    //     setUploading(false);
    //   }
    // };
    console.log(selectedFile?.tableNo,'selectedFile')
    const fetchImages = async (key) => {
      setUploading(true);
  
      try {
        const response = await axios.get('https://api.rcb.tdrsoftware.in/image', {
          params: {
            customerId: customerId,
            key: key // Optional key parameter
          }
        });
        setImages(response.data);
      } catch (err) {
        console.log(err.message);
      } finally {
        setUploading(false);
      }
    };
    const fetchData3=async()=>{
      const response= await axios.get(`https://api.rcb.tdrsoftware.in/api/getPrintData/${customerId}`)
      setPrintData(response.data)
      const rooms = response.data;
      const updatedRooms = await Promise.all(
        rooms.map(async (room) => {
          const imageResponse = await axios.get('https://api.rcb.tdrsoftware.in/image', {
            params: { customerId, key: room.image }
          });
          room.imageUrl = imageResponse.data;
          return room;
        })
      );
    }
    const handlePost = async () => {
      try {
        setUploading(true);
        const formData = new FormData();
        formData.append('customerId', customerId);
        formData.append('image', selectedFile);  // Ensure the field tableNo is 'image' here
    
        const response = await axios.post("https://api.rcb.tdrsoftware.in/images", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        const data = response.data;
        setImageURL(Object.values(data).join());
        toast.success("Image uploaded successfully");
        console.log(data,'data')
      } catch (err) {
        toast.error(err);
      } finally {
        setUploading(false);
      }
      
    };
    console.log(typeof(imageURL),'imageURL')
  
     
      useEffect(()=>{
      
      // const fetchImages = async () => {
      //   setUploading(true);
        
      //   try {
      //     const response = await axios.get('https://api.rcb.tdrsoftware.in/images', {
      //       headers: {
      //         'Content-Type': 'application/json'
      //       },
      //       params: {
      //         customerId: customerId
      //       }
      //     });
      //     setImages(response.data);
      //     console.log(response.data,'images');
      //   } catch (err) {
      //     console.log(err.message);
      //   } finally {
      //     setUploading(false);
      //   }
      // };
  
      // fetchImages();
      fetchData3()
      },[customerId])
      let room=printData.map((item)=>item.roomType);
      const roomTypes = room[0]?.split(',')?.map(item => item.trim());
      // let rooms=room.map((item)=>item)
      // console.log(rooms)
      //console.log(roomData)
      const [dueAmount, setDueAmount] = useState(0); 
      const [formData, setFormData] = useState({
       categoryName:"",
       mainGroup:"",
       indexNo:0,
       groupNo:0
        
        
      });
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
      const validateForm = () => {
        const requiredFields = [
          "categoryName",
          "mainGroup",
          "indexNo",
          "groupNo"
         
          
          
        ];
    
        for (const field of requiredFields) {
          if (!formData[field]) {
            return false;
          }
        }
        return true;
      };
    
      const handleSubmit =async (e) => {
        e.preventDefault();
        if (!validateForm()) {
          toast.error("Please fill in all required fields.");
          return;
        }
        // Here you can send formData to your backend server or process it further
       
        // Reset form after submission
        
        setFormData({
            categoryName:formData.categoryName,
            mainGroup:formData.mainGroup,
            indexNo:formData.indexNo,
            groupNo:formData.groupNo
        
        
        
         
        });
     
        
      };
  
    const handleImageChange = (e) => {
      setFormData({
        ...formData,
        image: e.target.files[0]
      });
    };
    const handleDueFocus = () => {
      setFormData({
        ...formData,
        child: formData.pax - formData.adult,
      });
    };
  
      const save=async(e)=>{
        if (!validateForm()) {
          toast.error("Please fill in all required fields.");
          return;
        }
        e.preventDefault()
         // Prepare form data for sending to backend
     
        try{
          const response=await axios.post("https://api.rcb.tdrsoftware.in/api/categoryRegister",{
            customerId:customerId,data:formData
          });
          console.log('Data Submitted: ',response.data);
          toast.success(response.data.message);
          setFormData({
            categoryName:"",
            mainGroup:"",
            indexNo:0,
            groupNo:0
          
          
        })
           
      }catch(error){
        if (error.response && error.response.status === 404) {
          // Handle specific error case: Room booking already exists
          toast.error("Category Registered with same name.");
        } else {
          console.error("Error:", error);
          toast.error("Failed to bill room. Please try again later.");
        }
      }
    }
 
      return (
        <Box   bg='#f7f7f7 '
        columns={{ sm: 2, md: 4 }}
        spacing='8'
        p='10'
        textAlign='center'
        
        color='black' width='100%' height='fit-content'   >
          
          <FormControl isRequired onSubmit={handleSubmit}>
            <Box display="grid"  gridGap={1} >
              <Flex
                display="flex"
                gap="10px"
                alignItems="center"
                justifyContent="space-evenly"
               
              >
                <Box width="40%"  display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                
                p={4}>
                  <FormLabel  >Category Name</FormLabel>
                  <Input w='70%' bg='#FAFAFA ' placeholder="Enter Category Name" border='1px solid #ffe4d9'   name='categoryName' value={formData.categoryName} onChange={handleInputChange} />
                </Box>
                <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Main Group</FormLabel>
                  <Input w='70%'  bg='#FAFAFA '  placeholder="Enter Main Group" border='1px solid #ffe4d9' name='mainGroup' value={formData.mainGroup} onChange={handleInputChange} />  
                   
                </Box>
              </Flex>
              <Box
                display="flex"
                gap="10px"
                alignItems="center"
                justifyContent="space-evenly"
                
              >
                <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Index No</FormLabel>
                  <Input w='70%' bg='#FAFAFA ' placeholder="Enter Index No" name='indexNo' value={formData.indexNo} onChange={handleInputChange}/>
                
                </Box>
                <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Group No</FormLabel>
                  <Input w='70%' bg='#FAFAFA ' placeholder="Enter Group No" name='groupNo' value={formData.groupNo} onChange={handleInputChange}/>
       
                  
                </Box>
                
              </Box>
              
              
            
              
              
             
                
               
             
             
             
              
              
              
            <hr/>
            <Box display='flex' flexDirection='column' rowGap='10px' mt={4}>
           
                </Box>
  
            </Box>
           
         
            <Button ac="submit" variant='solid' color='white' bg='tomato' _hover={{color:'tomato',bg:"white"}} mt={5} onClick={save}>Save</Button>
          </FormControl>
          
        </Box>
      );
  
    
  
    }
  
  
  
  
  
  export default CategoryRegister