
import React,{useState,useEffect} from 'react';
import Chart from 'react-apexcharts'
import axios from 'axios';
import toast from 'react-hot-toast';

const  Donut =()=> {
  
  const [foodBill,setFoodBill]=useState([]);
  const [customerBill,setCustomerBill]=useState([]);
  const customerId=localStorage.getItem('customerId')
  
  const fetchData=async()=>{
    try {
     const respose=await axios.get(`https://api.rcb.tdrsoftware.in/api/getQuickBillData/${customerId}`);
    setFoodBill(respose.data.quickBill);
    } catch (error) {
     toast.error(error.message);
    }

}
   const totalCash=foodBill.reduce((acc,sum)=> acc+sum.cash,0);
   const totalOnline=foodBill.reduce((acc,sum)=>acc+sum.online,0);
   console.log(totalCash,totalOnline)
   console.log(foodBill)



   

  useEffect(()=>{
fetchData()
  },[customerId])

 const options={
      series: [totalCash,totalOnline],
      labels: ['Cash', 'Online']
    }
  

  return (

    
      <div className="donut">
        <Chart options={options} series={options.series} labels={options.labels} type="donut" width="380" height={250} />
      </div>
    
  )
}

export default Donut;