import React, { useState } from 'react';
import { Card, Stack, CardBody, Text, Button, Box, FormControl, FormLabel, Input, SimpleGrid, CardFooter } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import axios from 'axios';

const GenerateOtp = () => {
 // const [otp, setOtp] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const navigate = useNavigate();

  const handleClick = async (customerId,phoneNo) => {
    try {
      const response = await axios.get(`https://api.rcb.tdrsoftware.in/api/forgot-password/${customerId}?phoneNo=${phoneNo}`, );
      console.log(response);
      toast.success(response.data.message);
      navigate('/forgot-password');
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100vh" bg="#FFEFD5">
      <Card
        overflow="hidden"
        variant="outline"
        width={{ base: '90%', md: '50%', lg: '35%' }} // Adjusting card width based on screen size
        p={6}
        bg="#FEA116"
        boxShadow="md"
      >
        <Stack spacing={1} alignItems="center">
          <CardBody>
            <FormControl isRequired>
              <Text fontSize="xl" as="b" mb={10} textAlign="center" display='flex' justifyContent='center' alignItems='center'>
                Forgot Password
              </Text>

              <SimpleGrid mb={4} placeItems='center' gap='10px' columns={2}>
                <FormLabel color="black">CustomerId</FormLabel>
                <Input width='full'
                  bg="#FAFAFA"
                  placeholder="Enter customerId"
                  value={customerId}
                  onChange={(e) => setCustomerId(e.target.value)}
                />
              </SimpleGrid>

              {/* <SimpleGrid mb={4} placeItems='center' gap='10px' columns={2}>
                <FormLabel color="black">Otp</FormLabel>
                <Input width='full'
                  bg="#FAFAFA"
                  placeholder="Enter 4 digit otp"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </SimpleGrid> */}

              <SimpleGrid mb={4} placeItems='center' gap='10px' columns={2}>
                <FormLabel color="black">Phone Number</FormLabel>
                <Input width='full'
                  
                  bg="#FAFAFA"
                  placeholder="Enter your Phone No"
                  value={phoneNo}
                  onChange={(e) => setPhoneNo(e.target.value)}
                />
              </SimpleGrid>
              <SimpleGrid mb={4} placeItems='center' gap='10px' columns={1}>
              <Button
                width="50%" // Full width button
                variant="solid"
                color="#FEA116"
                bg="#0F172B"
                _hover={{ color: 'white' }}
                onClick={()=>handleClick(customerId,phoneNo)}
              >
                Generate Otp
              </Button>
              </SimpleGrid>
            </FormControl>
          </CardBody>
         
            <Link to='/login' as='b'><b>Login </b></Link>
         
        </Stack>
      </Card>
    </Box>
  );
};

export default GenerateOtp;
