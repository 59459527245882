import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const KotContext = createContext();
export const useKotContext = () => useContext(KotContext);
const customerId = localStorage.getItem('customerId');

export const KotProvider = ({ children }) => {
  const [kotNumber, setKotNo] = useState('');
  const [serverName, setServerName] = useState('');
  const [kotCreatedTimes, setKotCreatedTimes] = useState({});
  const [holdTimes, setHoldTimes] = useState({});

  // Function to fetch kotNumber from the API
  const fetchKotNo = async (customerId, tableNo) => {
    try {
      const response = await axios.get(`https://api.rcb.tdrsoftware.in/api/getKotByTable/${customerId}/${tableNo}`);
      console.log(response, 'response');
      setKotNo(response.data.kot.kotNo);
      const kotData = response.data.kot;

      // Update kotCreatedTimes for the specific table without overwriting previous entries
      if (kotData && kotData.createdAt) {
        setHoldTimes(prev => ({
          ...prev,
          [tableNo]: {
            ...prev[tableNo],
            createdAt: response.data.kot.createdAt,
          },
        }));
      }
    } catch (error) {
      console.error('Error fetching kot number:', error);
    }
  };

  const fetchServerName = async (customerId, tableNo) => {
    try {
      const response = await axios.get(`https://api.rcb.tdrsoftware.in/api/getServerName/${customerId}/${tableNo}`);
      console.log(response, 'response');
      setServerName(response.data.serverName.name);
    } catch (error) {
      console.error('Error fetching server name:', error);
    }
  };

  // Function to update kotNumber via API
  const updateKotNo = async (customerId, newKotNo, tableNo) => {
    try {
      const response = await axios.post(`https://api.rcb.tdrsoftware.in/api/kotNumberRegister/${customerId}`, {
        data: { kotNo: newKotNo, tableNo: tableNo },
      });
      setKotNo(response.data.kot);

      // Update kotCreatedTimes for the specific table without overwriting previous entries
      setKotCreatedTimes((prev) => ({ ...prev, [tableNo]: new Date(response.data.kot.createdAt) }));
    } catch (error) {
      console.error('Error updating kot number:', error);
    }
  };

  const updateServerName = async (customerId, newServerName, tableNo) => {
    try {
      const response = await axios.post(`https://api.rcb.tdrsoftware.in/api/serverName`, {
        customerId: customerId,
        data: { name: newServerName, tableNo: tableNo },
      });
      setServerName(response.data.serverName.name);
      console.log(response.data.serverName, 'response');
    } catch (error) {
      console.error('Error updating server name:', error);
    }
  };

  // Function to clear kotNumber and reset createdAt timestamp for a specific table
// Function to clear kotNumber and reset createdAt timestamp for a specific table
const clearKotNo = async (customerId, tableNo) => {
  try {
    await axios.delete(`https://api.rcb.tdrsoftware.in/api/deleteKotByTable/${customerId}/${tableNo}`);
    setKotNo('');
    setServerName('');

    // Remove kotCreatedTimes and holdTimes for the specific table
    setKotCreatedTimes((prev) => {
      const updatedTimestamps = { ...prev };
      delete updatedTimestamps[tableNo];
      return updatedTimestamps;
    });

    setHoldTimes((prev) => {
      const updatedHoldTimes = { ...prev };
      delete updatedHoldTimes[tableNo];
      return updatedHoldTimes;
    });
  } catch (error) {
    console.error('Error clearing kot number:', error);
  }
};


  const clearServerName = async (customerId, tableNo) => {
    try {
      await axios.delete(`https://api.rcb.tdrsoftware.in/api/deleteServerName/${customerId}/${tableNo}`);
      setServerName('');
    } catch (error) {
      console.error('Error clearing server name:', error);
    }
  };

  // Effect to calculate and update hold time in minutes for each table based on createdAt
  useEffect(() => {
    const interval = setInterval(() => {
      setHoldTimes((prev) => {
        const updatedHoldTimes = { ...prev };
        Object.keys(kotCreatedTimes).forEach((tableNo) => {
          const timeDifference = Math.floor((new Date() - kotCreatedTimes[tableNo]) / (1000 * 60)); // Time in minutes
          updatedHoldTimes[tableNo] = timeDifference;
        });
        return updatedHoldTimes;
      });
    }, 60000); // Update every minute

    return () => clearInterval(interval); // Clear interval on cleanup
  }, [kotCreatedTimes]);

  return (
    <KotContext.Provider value={{ kotNumber, fetchKotNo, updateKotNo, clearKotNo, fetchServerName, updateServerName, clearServerName, serverName, holdTimes,setHoldTimes }}>
      {children}
    </KotContext.Provider>
  );
};
