import { useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useKotContext } from '../context/kotContext';


const UseKotPrint = (arr1, tableNo, totalAmount) => {
  
  const { updateKotNo,fetchServerName,serverName } = useKotContext();
  const [date,setDate]=useState('')
  const [time,setTime]=useState('') 
  const [kotData, setKotData] = useState({
    foodItem: arr1 || [],
    tableNo: tableNo || '',
    kotNo: '',
    totalBilling: totalAmount || 0,
    serverName:''
  });

  const customerId = localStorage.getItem('customerId');
  function getFormattedCurrentDate() {
    const now = new Date(); // Get the current date and time
  
    // Format the date as "DD-MM-YYYY"
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // getMonth() is 0-based, so +1
    const year = now.getFullYear();
  
    // Format the time as "HH:MM AM/PM"
    let hours = now.getHours();
    let minutes = String(now.getMinutes()).padStart(2, '0'); // Add leading zero to minutes if needed
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert 24-hour time to 12-hour time, adjusting midnight to 12
   const date=`${day}-${month}-${year} `;
   const time=`${hours}:${minutes}${period.toLowerCase()}`;
  
    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}${period.toLowerCase()}`;
    
    return [formattedDate,date,time];
  }
  
  console.log(serverName,'serverName')
  useEffect(()=>{
    fetchServerName(customerId,tableNo)
   const newDate= getFormattedCurrentDate()
   setDate(newDate[1])
   setTime(newDate[2])
  
  },[customerId,tableNo])

  // Function to handle KOT printing
  const kotPrint = async () => {
    try {
      const randomKotNumber = `${tableNo}-${Math.floor(Math.random() * 10000)}`;
      console.log(serverName,'serverName')

      // Update KOT data before sending to the backend
      const updatedKotData = {
        ...kotData,
        foodItem: arr1,
        kotNo: randomKotNumber,
        totalBilling: totalAmount,
        serverName:serverName
      };
      setKotData(updatedKotData);

      // Update KOT in context or state
      updateKotNo(customerId, randomKotNumber, tableNo);

      // POST the KOT data to the backend
      const response = await axios.post('https://api.rcb.tdrsoftware.in/api/kotBillRegister', {
        customerId: customerId,
        data: updatedKotData,
      });

      toast.success(response.data.message);

      const data = updatedKotData.foodItem || [];

      // Prepare print data as a string of HTML with CSS for 3-inch page size
      const printData = `
      <style>
        @page {
          size: 3in auto; /* Set page width to 3 inches */
          margin: 1;
          margin-bottom:10; /* Remove margins for accurate sizing */
        }
        body {
          width: 3in; /* Page width of 3 inches */
          margin: 0;
          font-size: 12px;
        }
        h2 {
          font-size: 14px;
          margin-bottom: 10px;
        }
        p {
          margin: 5px 0;
          font-size: 12px;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          font-size: 12px;
        }
        td {
          padding: 5px;
          border: 1px solid #000;
        }
        /* Make the name column larger and quantity smaller */
        td.name {
          width: 80%; /* Adjust this width as needed */
        }
        td.quantity {
          width: 20%; /* Adjust this width as needed */
        }
      </style>
      <body>
        <h2>KOT Details:</h2>
        <p>Table No.: ${updatedKotData.tableNo}</p>
        <p>KOT No.: ${updatedKotData.kotNo}</p>
        <p>Server : ${updatedKotData.serverName}</p>
        <p>Date : ${date}</p>
        <p>Time : ${time}</p>
        <p>Food Items:</p>
        <table>
          <thead>
            <tr>
              <td class="name">Name</td>
              <td class="quantity">Quantity</td>
             
            </tr>
          </thead>
          <tbody>
            ${Array.isArray(data) ? data.map((item) => `
              <tr key="${item._id}">
                <td class="name">${item.name}-(${item.note?item.note:""})</td>
                <td class="quantity">${item.quantity}</td>
                
              </tr>
            `).join('') : ''}
          </tbody>
        </table>
      </body>
    `;
    

      // Create a hidden iframe for printing
      const iframe = document.createElement('iframe');
      iframe.style.position = 'fixed';
      iframe.style.top = '-9999px'; // Hide the iframe offscreen
      document.body.appendChild(iframe);

      // Write content to iframe
      const iframeDoc = iframe.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(printData);
      iframeDoc.close();

      // Trigger the print dialog
      iframe.contentWindow.focus();
      iframe.contentWindow.print();

      // Remove iframe after printing
      setTimeout(() => {
        document.body.removeChild(iframe);
        window.location.reload();
      }, 1000);

    } catch (error) {
      console.error('Error saving KOT:', error);
      toast.error('Error in KOT');
    }
  };

  return kotPrint; // Return the function to be used for printing KOT
};

export default UseKotPrint;
