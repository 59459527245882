import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Input,
  Table,
  TableCaption,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tfoot,
  TableContainer,
  List,
  ListItem,Select,
  Button
} from "@chakra-ui/react";
import toast from 'react-hot-toast';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import jsPDF from "jspdf";
import "jspdf-autotable";
//import toast from 'react-hot-toast';

const DailySalesSummary = () => {
  const [data, setData] = useState([]);
  const [wineData, setWineData] = useState([]);
  const [payment, setPayment] = useState({});
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState(new Date());
  const [searchItem, setSearchItem] = useState('');
  const [unit,setUnit]=useState('')
  const [foodData, setFoodData] = useState([]);
  const [filteredFoodData, setFilteredFoodData] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(-1); // Track selected item index

  const customerId = localStorage.getItem('customerId');

  const fetchData = async () => {
    try {
      const response = await axios.get(`https://api.rcb.tdrsoftware.in/api/getSalesSummary/${customerId}?startDate=${startDate}&endDate=${endDate}&searchItem=${searchItem}`);
      setData(response.data.foodSales);
      setWineData(response.data.wineSales);
      setPayment(response.data.paymentSummary);
    } catch (error) {
      toast.error('Error fetching data');
    }
  };

  const fetchFoodData = async () => {
    try {
      const response = await axios.get(`https://api.rcb.tdrsoftware.in/api/getFoodAndWineData/${customerId}`);
      setFoodData(response.data.items);
      setFilteredFoodData(response.data.items);  // Initial filtered data to all items
    } catch (error) {
      toast.error('Error fetching food data');
    }
  };

  useEffect(() => {
    fetchData();
    fetchFoodData();
  }, [customerId, startDate, endDate, searchItem]);
  
  // Export to Excel function
  const consolidateDataForExcel = () => {
    const consolidatedData = [];
    const maxRows = Math.max(data.length, wineData.length, 1); // 1 for at least the payment row
  
    // Initialize total quantities and amounts
    let totalFoodQuantity = 0;
    let totalFoodAmount = 0;
    let totalwineQuantity = 0;
    let totalwineAmount = 0;
  
    for (let i = 0; i < maxRows; i++) {
      // Accumulate total quantities and amounts for food
      totalFoodQuantity += data[i]?.totalQuantity || 0;
      totalFoodAmount += data[i]?.totalAmount || 0;
      totalwineQuantity += wineData[i]?.totalQuantity || 0;
      totalwineAmount += wineData[i]?.totalAmount || 0;
  
      consolidatedData.push({
        Food_Item: data[i]?._id || '',
        Food_Rate: data[i]?.rate || '',
        Food_TotalQuantity: data[i]?.totalQuantity || '',
        Food_TotalAmount: data[i]?.totalAmount || '',
        Wine_Item: wineData[i]?._id || '',
        Wine_Rate: wineData[i]?.rate || '',
        Wine_TotalQuantity: wineData[i]?.totalQuantity || '',
        Wine_TotalAmount: wineData[i]?.totalAmount || '',
        Cash: i === 0 ? payment.cashTotal || '' : '', // Only show once
        Online: i === 0 ? payment.onlineTotal || '' : '',
        Card: i === 0 ? payment.cardTotal || '' : '',
        NetAmount: i === 0 ? (payment.cashTotal + payment.onlineTotal + payment.cardTotal) || '' : '',
      });
    }
  
    // Add a final row for totals
    consolidatedData.push({
      Food_Item: 'Total',
      Food_Rate: '',
      Food_TotalQuantity: totalFoodQuantity,
      Food_TotalAmount: totalFoodAmount,
      Wine_Item: '',
      Wine_Rate: '',
      Wine_TotalQuantity: totalwineQuantity, // No total for wine quantity
      Wine_TotalAmount: totalwineAmount,   // No total for wine amount
      Cash: '',
      Online: '',
      Card: '',
      NetAmount: '',
    });
  
    return consolidatedData;
  };
  
  const exportToExcel = () => {
    const dataToExport = consolidateDataForExcel();
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Daily Sales Summary");
    
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, `${startDate}-${endDate}-DailySalesSummary.xlsx`);
  };
  const exportToPDF = () => {
    const dataToExport = consolidateDataForExcel();
    
    // Initialize jsPDF
    const pdf = new jsPDF();
  
    // Define the columns for the table
    const columns = [
      { header: "Food Item", dataKey: "Food_Item" },
      { header: "Food Rate", dataKey: "Food_Rate" },
      { header: "Food Total Quantity", dataKey: "Food_TotalQuantity" },
      { header: "Food Total Amount", dataKey: "Food_TotalAmount" },
      { header: "Wine Item", dataKey: "Wine_Item" },
      { header: "Wine Rate", dataKey: "Wine_Rate" },
      { header: "Wine Total Quantity", dataKey: "Wine_TotalQuantity" },
      { header: "Wine Total Amount", dataKey: "Wine_TotalAmount" },
      { header: "Cash", dataKey: "Cash" },
      { header: "Online", dataKey: "Online" },
      { header: "Card", dataKey: "Card" },
      { header: "Net Amount", dataKey: "NetAmount" }
    ];
  
    // Add title to PDF
    pdf.text("Daily Sales Summary", 14, 20);
  
    // Add autoTable to render the data in a table format
    pdf.autoTable({
      startY: 30,
      head: [columns.map(col => col.header )],
      body: dataToExport.map(row => columns.map(col => row[col.dataKey] || "")),
      theme: "grid",
      styles: {
        fontSize: 8,
        cellPadding: 2,
      },
      headStyles: {
        fillColor: [0, 57, 107],
        textColor: [255, 255, 255]
      }
    });
  
    // Save the PDF
    pdf.save(`${startDate}-${endDate}DailySalesSummary.pdf`);
  };

  // Filter foodData based on searchItem input
  useEffect(() => {
    if (searchItem) {
      const filtered = foodData.filter(item => item.name.toLowerCase().includes(searchItem.toLowerCase()));
      setFilteredFoodData(filtered);
    } else {
      setFilteredFoodData(foodData);
    }
    setHighlightedIndex(-1); // Reset the highlight when search input changes
  }, [searchItem, foodData]);

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      setHighlightedIndex((prevIndex) =>
        prevIndex < filteredFoodData.length - 1 ? prevIndex + 1 : 0
      );
    } else if (e.key === 'ArrowUp') {
      setHighlightedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : filteredFoodData.length - 1
      );
    } else if (e.key === 'Enter' && highlightedIndex >= 0) {
      // Set the search input to the selected item
      setSearchItem(filteredFoodData[highlightedIndex].name);
      setFilteredFoodData([]); // Clear suggestions after selection
    }
  };

  return (
    <Box>
      <Box display='flex' flexDirection='row' justifyContent='center' gap={3} p={5} mb={10}>
        <Input
          width='20%'
          type='date'
          name='startDate'
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <Input
          width='20%'
          type='date'
          name='endDate'
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        <Box width='20%' position="relative">
          <Input
            placeholder="Search Item"
            value={searchItem}
            onChange={(e) => setSearchItem(e.target.value)}
            onKeyDown={handleKeyDown} // Add keydown handler for arrow navigation
          />
          <List position="absolute" bg="white" zIndex="1" width="100%" maxHeight="150px" overflowY="auto">
            {filteredFoodData.map((item, index) => (
              <ListItem
                key={index}
                bg={index === highlightedIndex ? "gray.200" : "white"}
                padding="5px"
                cursor="pointer"
                onMouseEnter={() => setHighlightedIndex(index)}
                onMouseLeave={() => setHighlightedIndex(-1)}
                onClick={() => setSearchItem(item.name)} // Set item on click
              >
                {item.name}
              </ListItem>
            ))}
          </List>
          <Select width={'10%'} name='unit' value={unit} onChange={(e) => setUnit(e.target.value)} >
            <option value="30ml peg">30ml peg</option>
            <option value="60ml peg">60ml peg </option>
            <option value="bottle">bottle</option>
          </Select>
        </Box>
        <Button colorScheme='green' onClick={exportToExcel}>Export Excel</Button>
        <Button colorScheme='red' onClick={exportToPDF}>Export Pdf</Button>
      </Box>

      <Box width='full' mt={150} bg='#E3D9FC'>

      <TableContainer width='full' p={2}>
        <Table size='sm' variant='striped' colorScheme='green'>
          <TableCaption>Daily Sales Summary</TableCaption>
          <Thead>
            <Tr>
              <Th>Food Item</Th>
              <Th>Wine Item</Th>
              <Th>Payment</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Td>
              {/* Food Sales Table */}
              <Table size='xsm' variant='striped' colorScheme='orange'>
                <TableCaption>Food Sales Summary</TableCaption>
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Rate</Th>
                    <Th>Qty</Th>
                    <Th>Amount</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.map((item, index) => (
                    <Tr key={index}>
                      <Td>{item._id}</Td>
                      <Td>{item.rate}</Td>
                      <Td>{item.totalQuantity}</Td>
                      <Td>{item.totalAmount}</Td>
                    </Tr>
                  ))}
                </Tbody>
                <Tfoot p={10}>
                <tr>
    <td colSpan={3} style={{textAlign: 'right',fontWeight:'bold',padding:"5px",fontSize:'15px'}}>
     Total Qty: {data.reduce((total, item) => total + item.totalQuantity, 0)}
    </td>
    <td colSpan={4} style={{textAlign: 'right',marginRight:'20px',fontWeight:'bold',padding:"5px",fontSize:'15px'}}>
     Total Amt: {data.reduce((total, item) => total + item.totalAmount, 0)}
    </td>
  </tr>
                </Tfoot>
              </Table>
            </Td>
            <Td>
              {/* Wine Sales Table */}
              <Table size='xsm' variant='striped' colorScheme='blue'>
                <TableCaption>Wine Sales Summary</TableCaption>
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Rate</Th>
                    <Th>Qty</Th>
                    <Th>Amount</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {wineData.map((item, index) => (
                    <Tr key={index}>
                      <Td>{item._id}</Td>
                      <Td>{item.rate}</Td>
                      <Td>{item.totalQuantity}</Td>
                      <Td>{item.totalAmount}</Td>
                    </Tr>
                  ))}
                </Tbody>
                <Tfoot p={2}>
                <tr>
    <td colSpan={3} style={{textAlign: 'right',fontWeight:'bold',padding:"5px",fontSize:'15px'}}>
     Total Qty: {wineData.reduce((total, item) => total + item.totalQuantity, 0)}
    </td>
    <td colSpan={4} style={{textAlign: 'right',marginRight:'20px',fontWeight:'bold',padding:"5px",fontSize:'15px'}}>
     Total Amt: {wineData.reduce((total, item) => total + item.totalAmount, 0)}
    </td>
  </tr>
                </Tfoot>
              </Table>
            </Td>
            <Td>
              {/* Payment Summary Table */}
              <Table size='sm' variant='striped' colorScheme='yellow' p={2}>
                <TableCaption>Payment Summary</TableCaption>
                <Thead>
                  <Tr>
                    <Th mr={2}>Cash</Th>
                    <Th mr={2}>Online</Th>
                    <Th mr={2}>Card</Th>
                    <Th mr={2}>NetAmount</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td mr={2}>{payment.cashTotal}</Td>
                    <Td mr={2}>{payment.onlineTotal}</Td>
                    <Td mr={2}>{payment.cardTotal}</Td>
                    <Td mr={2}>{payment.cashTotal + payment.onlineTotal + payment.cardTotal}</Td>
                  </Tr>
                </Tbody>
         
              </Table>
            </Td>
          </Tbody>
        </Table>
      </TableContainer>
      </Box>
    </Box>
  );
};

export default DailySalesSummary;
