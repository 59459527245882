import React, { useEffect } from "react";
import { VStack, Button, Text, useColorModeValue, Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,Popover, PopoverTrigger, PopoverContent, PopoverBody,  Portal } from "@chakra-ui/react";
  import axios from 'axios'
import { Link, useLocation } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { IoPeopleCircleSharp } from "react-icons/io5";
import { MdOutlineBedroomParent } from "react-icons/md";
import { IoFastFoodOutline } from "react-icons/io5";
import { TbCoinRupee } from "react-icons/tb";
import { FaHome } from "react-icons/fa";
import { useState } from "react";
import { useShowSidebar } from "../modules/context/showSidebarContext";
const Sidebar = () => {
  const {showSidebar}=useShowSidebar();
  console.log(showSidebar,
    "showSidebar");
  
  const [data,setData]=useState([]);
  const [show,setShow]=useState(false)
  const customerId=localStorage.getItem('customerId')
  const fetchData=async()=>{
    const response= await axios.get(`https://api.rcb.tdrsoftware.in/api/getPrintData/${customerId}`)
    setData(response.data)
}
  console.log(customerId);
  const location = useLocation();

  // Function to determine if a menu item is active
  const isActive = (path) => {
    return location.pathname === path;
  };
  useEffect(()=>{
    fetchData()
  },[customerId])
  const handleShow=()=>{
    setShow(!show)
  }

  // Function to apply active style to the menu item
  const getMenuItemStyle = (path) => {
    return isActive(path) ? { background: 'tomato', color: 'white' } : {};
  };

  return (
    <>
   {showSidebar ?<VStack spacing={4} align="stretch" bg="white" pt={3}  width="250px" minHeight='1000px'   >
      
   {data.map((item)=>(
    <Text mt={2} mb={4} as='b' fontSize="lg" textAlign='center'>{item.name}</Text>

   ))}

<Button sx={{ borderRadius: 'none' }}
bg='transparent'
  as={Link}
  to="/dashboard"
  w="full"
  p={2}
 display='flex'
  
  style={getMenuItemStyle('/dashboard')}
>
 <FaHome mr={2} mt={2}/> Dashboard
</Button>
<Button sx={{ borderRadius: 'none' }}
bg='transparent'
  as={Link}
  to="/printRegister"
  w="full"
  p={2}
 display='flex'
  
  style={getMenuItemStyle('/printRegister')}
>
 <FaHome mr={2} mt={2}/> Restaurant Info
</Button>




<Button sx={{ borderRadius: 'none' }}
  as={Link}
  to="/table-register"

  variant="ghost"
  style={getMenuItemStyle('/table-register')}
>
 <MdOutlineBedroomParent mr={2} mt={2}/> Table Registration
</Button>
<Button sx={{ borderRadius: 'none' }}
  as={Link}
  to="/wine-register"

  variant="ghost"
  style={getMenuItemStyle('/wine-register')}
>
 <MdOutlineBedroomParent mr={2} mt={2}/> Wine Registration
</Button>
<Button sx={{ borderRadius: 'none' }}
  as={Link}
  to="/category-register"

  variant="ghost"
  style={getMenuItemStyle('/category-register')}
>
 <MdOutlineBedroomParent mr={2} mt={2}/>Wine Category Registration
</Button>
<Button sx={{ borderRadius: 'none' }}
  as={Link}
  to="/waiter-register"

  variant="ghost"
  style={getMenuItemStyle('/waiter-register')}
>
 <MdOutlineBedroomParent mr={2} mt={2}/>Waiter Register
</Button>
<Button sx={{ borderRadius: 'none' }}
  as={Link}
  to="/food-register"

  variant="ghost"
  style={getMenuItemStyle('/food-register')}
>
<IoFastFoodOutline mr={2} w='6px'/>  Food-Item Registration
</Button>




<Button sx={{ borderRadius: 'none' }}
  as={Link}
  to="/restaurant"

  variant="ghost"
  style={getMenuItemStyle('/restaurant')}
>
<IoFastFoodOutline mr={2} w='6px'/>  Restaurant Bill
</Button>
<Button sx={{ borderRadius: 'none' }}
  as={Link}
  to="/summary"

  variant="ghost"
  style={getMenuItemStyle('/summary')}
>
<IoFastFoodOutline mr={2} w='6px'/>  Report Summary
</Button>

</VStack>:""} </>
  );
};

export default Sidebar;
