import React,{createContext,useContext,useState} from 'react';
const ShowSidebarContext = createContext();
export const ShowSidebarProvider=({children})=>{
    const [showSidebar,setShowSidebar]=useState(true);
    const handleShowSidebar=()=>{
        setShowSidebar(!showSidebar);
    }
    return(
        <ShowSidebarContext.Provider value={{showSidebar,handleShowSidebar}}>
            {children}
        </ShowSidebarContext.Provider>
    )
}
export  const useShowSidebar=()=>{
    return useContext(ShowSidebarContext);
}