// api.js

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import {toast} from 'react-hot-toast'
//https://api.rcb.tdrsoftware.in/api

const baseURL = 'https://api.rcb.tdrsoftware.in/api'; // Replace with your API base URL

export const loginUser = async (credentials) => {
    try {
        const response = await axios.post(`${baseURL}/login`, credentials);

        // Store the token in cookies
        const token = response.data.user.token;
        if (token) {
            Cookies.set('token', token, { expires: 1 }); // Store token for 7 days
        }

        toast.success(response.data.message, {
            duration: 3000, // toast duration in milliseconds
        });

        return response.data; // Assuming your API returns user data including customerId

    } catch (error) {
        toast.error(error.response.data.message);
        throw new Error(error.response.data.message); // Handle error responses from API
    }
};

export const registerUser = async (userData) => {
    try {
        const response = await axios.post(`${baseURL}/register`, userData);
        return response.data; // Assuming your API returns user data including customerId
    } catch (error) {
        throw new Error(error.response.data.message); // Handle error responses from API
    }
};
