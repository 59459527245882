import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  SimpleGrid,
  Box,
  Input,
  FormLabel,Select,
  InputGroup,
  InputLeftAddon,useDisclosure,
  Checkbox,
  

} from '@chakra-ui/react';
import { useState,useEffect } from 'react';
import { IoMdClose } from "react-icons/io";
import axios from 'axios'
import toast from 'react-hot-toast';

const WineUpdateModal = ({isOpen,onClose,id,customerId,onOpen,setSelectId,data,category,type}) => {
  console.log(type,'type')
    
    console.log(id,'id')    
    const [selectedFile, setSelectedFile] = useState(null);
    const[newImageURL,setnewImageURL]=useState(null);
    const [uploading, setUploading] = useState(false);
    const [formData,setFormData]=useState({
        itemCode:"",
        code:"",
        name:"",
        category:"",
        brand:"",
        packing:"",
        mrp:0,
        purchaseRate:0,
        acRate:0,
        acHalfRate:0,
        nonAcRate:0,
        nonAcHalfRate:0,
        netRate:0,
        closingStock:0,
        stockMl:0,
        itemType:"",
        itemPhoto:"",
        halfPlate:false
  
  
       
  
        
       
    
    
    })
    const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0]);
     
      
    }
    const handlePost = async () => {
      try {
        setUploading(true);
        const formData = new FormData();
        formData.append('customerId', customerId);
        formData.append('image', selectedFile);  // Ensure the field name is 'image' here
    
        const response = await axios.post("https://api.rcb.tdrsoftware.in/images", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        const data = response.data;
        setnewImageURL(Object.values(data).join());
     toast.success("Image uploaded successfully");
        console.log(data,'data')
      } catch (err) {
        toast.error(err);
      } finally {
        setUploading(false);
      }
      
    };
    console.log(newImageURL,'newImageURL')
    
    //console.log(unique,'unique')
    

    const handleEdit = async (e) => {
        e.preventDefault();
        const updatedFormData = { ...formData, itemPhoto: newImageURL || formData.itemPhoto };
        
        try {
            // Make the PUT request with formData directly
            const response = await axios.put(`https://api.rcb.tdrsoftware.in/api/updateWineData/${customerId}/${id}`,{data:updatedFormData});
            
            toast.success("Wine data updated");
            console.log(response, 'response');
            //setSelectId(null);
            // Optionally reset the form after a successful update
            setFormData({
               itemCode:"",
        code:"",
        name:"",
        category:"",
        brand:"",
        packing:"",
        mrp:0,
        purchaseRate:0,
        acRate:0,
        acHalfRate:0,
        nonAcRate:0,
        nonAcHalfRate:0,
        netRate:0,
        closingStock:0,
        stockMl:0,
        itemType:"",
        itemPhoto:"",
        halfPlate:false
            });
            window.location.reload();
    
        } catch (error) {
            toast.error("Error updating Food Data: " + error.message);
            console.error("Update failed:", error);
        }
    };
  
   console.log(Object.keys(data)+" :"+Object.values(data),'data')
   

    useEffect(() => {
        if (data) {
            setFormData({
              itemCode:data.itemCode || "",
              code:data.code || "",
              name:data.name || "",
              category:data.category || "",
              brand:data.brand || "",
              packing:data.packing || "",
              mrp:data.mrp || 0,
              purchaseRate:data.purchaseRate || 0,
              acRate:data.acRate || 0,
              acHalfRate:data.acHalfRate || 0,
              nonAcRate:data.nonAcRate || 0,
              nonAcHalfRate:data.nonAcHalfRate || 0,
              netRate:data.netRate || 0,
              closingStock:data.closingStock || 0,
              stockMl:data.stockMl || 0,
              itemType:data.itemType || "",
              itemPhoto:data.itemPhoto || "",
              halfPlate:data.halfPlate || false,
                
            });
        }
    }, [data]);
    const scroll='inside'
   
    const handleInputChange=(e)=>{
     const {name,value}=e.target;
     setFormData({
        ...formData,
       [name] : value 
     })
    }
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: checked, // Set formData.halfPlate to true/false based on checkbox state
        }));
      };
     
    const handleSubmit=(e)=>{
       
    }
    const handleClick=()=>{
      onClose()
      setSelectId(null);
    }
  return (
    <Modal  width='full' size='3xl' isOpen={isOpen} onClose={onClose} onOpen={onOpen} scrollBehavior={scroll}  >
      <ModalOverlay />
      <ModalContent bg='#f9f1e3'>
        <ModalHeader display='flex' justifyContent='space-between' alignItems='center'>
            <Text> {data?.name}</Text>
            <Button colorScheme='red' onClick={handleClick} ><IoMdClose/></Button></ModalHeader>
       
        <ModalBody >
         <SimpleGrid columns={2} gap={4} placeItems='left'>
            <InputGroup>
            <InputLeftAddon>Name</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='name' placeholder='Enter name' value={formData.name} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>ItemCode</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='itemCode' placeholder='Enter Itemcode' value={formData.itemCode} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Short Code</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='code' placeholder='Enter Code' value={formData.code} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Category</InputLeftAddon>
           <Select width='70%' type='date' bg='white' onChange={handleInputChange} name='category' placeholder='Enter Category' value={formData.category} >
           {category.map((name)=>(<option key={name._id} value={name}>{name}</option>))} 
           </Select>
            
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Brand</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='brand' placeholder='Enter Brand' value={formData.brand} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Packing</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='pcaking' placeholder='Enter packing' value={formData.packing} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Mrp</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='mrp' placeholder='Enter Mrp' value={formData.mrp} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Purchase Rate</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='purchaseRate' placeholder='Enter PurchaseRate' value={formData.purchaseRate} />
           </InputGroup>
           <InputGroup> <InputLeftAddon>Ac Full Rate</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='acRate' placeholder='Enter Ac Half Rate' value={formData.acRate} />
           </InputGroup> 
           
           {formData.halfPlate ?<InputGroup> <InputLeftAddon>Ac Half Rate</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='acHalfRate' placeholder='Enter Ac Half Rate' value={formData.acHalfRate} />
           </InputGroup> : ""}
           <InputGroup>
            <InputLeftAddon>NonAc Rate</InputLeftAddon>
           <Input width='68%' bg='white' onChange={handleInputChange} name='nonAcRate' placeholder='Enter NonAc Rate' value={formData.nonAcRate} />
           </InputGroup>
           {formData.halfPlate ?<InputGroup> <InputLeftAddon>NonAc Half Rate</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='nonAcHalfRate' placeholder='Enter NonAc Half Rate' value={formData.nonAcHalfRate} />
           </InputGroup> : ""}
          
           
           
          
          
           <InputGroup>
            {/* <InputLeftAddon>Half Plate Available?</InputLeftAddon> */}
            <Checkbox w='70%' bg='#fafafa' value={formData.halfPlate} name='halfPlate' isChecked={formData.halfPlate} onChange={handleCheckboxChange}>Half Available ?</Checkbox>
          
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Net Rate</InputLeftAddon>
           <Input width='68%' bg='white' onChange={handleInputChange} name='netRate' placeholder='Enter net rate' value={formData.netRate} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Item type</InputLeftAddon>
           <Select width='68%' bg='white' onChange={handleInputChange} name='itemType' placeholder='Enter Type' value={formData.itemType} >
            {type?.map((item)=>(<option key={item._id} value={item}>{item}</option>))}
           </Select>
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>StockMl</InputLeftAddon>
           <Input width='68%' bg='white' onChange={handleInputChange} name='stockMl' placeholder='Enter Stock Ml' value={formData.stockMl} />
           </InputGroup>
           <InputGroup>
                <InputLeftAddon>Image</InputLeftAddon>
                <Input w='70%' type="file" bg='#FAFAFA ' onChange={handleFileChange}  />
                <Button variant='solid' onClick={handlePost} disabled={uploading}>
        {uploading ? "Uploading..." : "Upload"}
      </Button>
                
              </InputGroup>
          
          
          
           

         </SimpleGrid>
         <Box display='flex' alignItems='center' justifyContent='center' gap={4} mt={2}>
         
         <Button colorScheme='green' onClick={(e)=>handleEdit(e)} >Edit</Button>
         </Box>
        </ModalBody>

        <ModalFooter>
         
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WineUpdateModal;
