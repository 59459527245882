import React from "react";
import { Box } from "@chakra-ui/react";
import Navbar from "../components/Navbar/Navbar";
import Sidebar from "../components/Sidebar";
import { Route, Routes } from "react-router-dom";
import PrintData from "../modules/print/printData.js";
import TableRegister from "../modules/Table/tableRegister.js";
import TableStatus from "../modules/Table/tableStatus.js";
import WineRegister from "../modules/Wine/wineRegister.js";
import WineData from "../modules/Wine/wineData.js";
import CategoryRegister from "../modules/category/categoryRegister.js";
import FoodRegister from "../modules/food/foodRegister.js";
import FoodData from "../modules/food/foodData.js";
import RestaurentData from "../modules/restaurant/bill.js";
import QuickBillData from "../modules/food/quickBillData.js";
import KotBillData from "../modules/food/kotBillData.js";
import Cookie from 'js-cookie';
import { BillItemsProvider } from "../modules/context/tableItemsContext.js";
import { KotProvider } from "../modules/context/kotContext.js";
import ForgotPassword from "../modules/auth/Forgot/forgotPassword.js";
import GenerateOtp from "../modules/auth/Forgot/generateOtp.js";
import SupplyRegister from "../modules/inventory/supplyRegister.js";
import ItemRegister from "../modules/inventory/itemRegister.js";
import StoreRegister from "../modules/inventory/storeRegister.js";
import StaffRegister from "../modules/inventory/staffRegister.js";
import PurchaseRegister from "../modules/inventory/purchaseRegister.js";
import StoreData from "../modules/inventory/storeData.js";
import StockTransfer from "../modules/inventory/stockTransfer.js";
import PurchaseReport from "../modules/inventory/purchaseReport.js";
import Dashboard from "../modules/dashboard/index.js";
import Summary from "../modules/summary/summary.js";
import WaiterRegister from "../modules/waiter/waiterRegister.js";
import Footer from "../components/Footer/footer.js";
import DailySalesSummary from "../modules/food/dailyLedger.js";
import StockTransferData from "../modules/inventory/stockTransferData.js";
import DailyTransferSummary from "../modules/inventory/stockTransferSummary.js";
import { TableTimeProvider } from "../modules/context/tableTimeContext.js";
import { ShowSidebarProvider, useShowSidebar } from "../modules/context/showSidebarContext.js";

const Layout = () => {
  const token = Cookie.get('token');
  const { showSidebar } = useShowSidebar();
  console.log(showSidebar,'showSidebar')

  return (
    <ShowSidebarProvider>
      <Box width='full' h='full'>
        <Box display="flex">
          { token && <Sidebar  />}
          <Box display='flex' flexDirection='column' flexGrow={1} overflow='hidden'>
            {token && <Navbar />}
            <Box overflowY='auto' flexGrow={1}>
            <ShowSidebarProvider>
              <BillItemsProvider>
                <KotProvider>
                  <TableTimeProvider>
                    <Routes>
                      {token ? (
                        <>
                          <Route path="/printRegister" element={<PrintData />} />
                          <Route path="/table-register" element={<TableRegister />} />
                          <Route path="/table-status" element={<TableStatus />} />
                          <Route path="/wine-register" element={<WineRegister />} />
                          <Route path="/wineData" element={<WineData />} />
                          <Route path="/category-register" element={<CategoryRegister />} />
                          <Route path="/food-register" element={<FoodRegister />} />
                          <Route path="/food" element={<FoodData />} />
                          <Route path="/restaurant" element={<RestaurentData />} />
                          <Route path="/quickBill" element={<QuickBillData />} />
                          <Route path="/kotBill" element={<KotBillData />} />
                          <Route path="/supply-register" element={<SupplyRegister />} />
                          <Route path="/item-register" element={<ItemRegister />} />
                          <Route path="/store-register" element={<StoreRegister />} />
                          <Route path="/staff-register" element={<StaffRegister />} />
                          <Route path="/purchase-register" element={<PurchaseRegister />} />
                          <Route path="/store" element={<StoreData />} />
                          <Route path="/stock-transfer" element={<StockTransfer />} />
                          <Route path="/purchase-report" element={<PurchaseReport />} />
                          <Route path="/dashboard" element={<Dashboard />} />
                          <Route path="/summary" element={<Summary />} />
                          <Route path="/waiter-register" element={<WaiterRegister />} />
                          <Route path="/dailySales" element={<DailySalesSummary />} />
                          <Route path="/stock-transfer-report" element={<StockTransferData />} />
                          <Route path="/stock-transfer-summary" element={<DailyTransferSummary />} />
                        </>
                      ) : (
                        <>
                          <Route path="/forgot-password" element={<ForgotPassword />} />
                          <Route path="/generate-otp" element={<GenerateOtp />} />
                        </>
                      )}
                    </Routes>
                  </TableTimeProvider>
                </KotProvider>
              </BillItemsProvider>
              </ShowSidebarProvider>
            </Box>
          </Box>
        </Box>
        {token && <Footer />}
      </Box>
    </ShowSidebarProvider>
  );
};

export default Layout;
