import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';
import { update } from 'lodash';

const BillItemsContext = createContext();

export const useBillItems = () => {
  const customerId = localStorage.getItem('customerId');
  const context = useContext(BillItemsContext);
  if (!context) {
    throw new Error('useBillItems must be used within a BillItemsProvider');
  }
  return context;
};

export const BillItemsProvider = ({ children }) => {
  const [billItems, setBillItems] = useState([]);

  // Fetch bill items from the server for a specific table
  const fetchTableBillItems = async (customerId, tableNo) => {
    try {
      const response = await axios.get(`https://api.rcb.tdrsoftware.in/api/getItems/${customerId}/${tableNo}`);
      const items = response.data.items;
      const newBillItems = [...billItems, { tableNo, items }];
      setBillItems(newBillItems);
    } catch (error) {
      console.error("Error fetching bill items:", error);
    }
  };

  // Add an item to the bill
  const addToBill = async (customerId, item, isAc, isHalf, tableNo,isBottle) => {
    console.log(item, 'item', tableNo);

    // Determine rateType based on AC and Half Plate status
    const rateType = isBottle 
    ? "purchaseRate" // If it's a bottle, use "purchaseRate"
    : isAc
      ? (isHalf ? "acHalfRate" : "acRate") // If AC, check if it's a half plate
      : (isHalf ? "nonAcHalfRate" : "nonAcRate"); // If non-AC, check if it's a half plate
  

    // Adjust item name based on half-plate status
    // const itemName = isHalf && item.stockMl ? `${item.name} 30ml peg`: isHalf ? `${item.name} half plate` : item.name;
    const itemName =isBottle? `${item.name+" bottle"}`  : isHalf 
  ? `${item.name} ${item.purchaseRate ? '30ml peg' : 'half plate'}`
  : item.purchaseRate 
    ? `${item.name} ${item.itemType === 'Beer' || item.itemType === "BEER" || item.itemType === "beer" || item.itemType === "LAB" || item.itemType ==="NON ALCHOHOLIC" ? 'bottle' : '60ml peg'}`
    : item.name;
    const unit = isBottle ? `${"bottle"}` : isHalf 
    ? ` ${item.purchaseRate ? '30ml peg' : 'half plate'}`
    : item.purchaseRate 
      ? ` ${item.itemType === 'Beer'  || item.itemType === "BEER" || item.itemType === "beer" || item.itemType === "LAB" || item.itemType ==="NON ALCHOHOLIC" ? 'bottle' : '60ml peg'}`
      : '1 plate';
console.log(unit,'unit')

    try {
      // Send new bill item to the server
      const newBillItem = { tableNo, item: { ...item, name: itemName, quantity: 1, price: item[rateType], rateType: item[rateType],unit :unit } };
      const response = await axios.post(`https://api.rcb.tdrsoftware.in/api/addBillItem/${customerId}`, newBillItem);
      console.log(newBillItem, 'new');

      // Update local state after successful addition
      const updatedBill = response.data.bill;
      const tableBillIndex = billItems.findIndex(bill => bill.tableNo === tableNo);

      if (tableBillIndex !== -1) {
        const newBillItems = [...billItems];
        newBillItems[tableBillIndex] = { ...billItems[tableBillIndex], items: updatedBill.items };
        setBillItems(newBillItems);
      } else {
        setBillItems([...billItems, { tableNo, items: updatedBill.items }]);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // Update item quantity in the bill
  const updateQuantity = async (customerId, tableNo, index, quantity) => {
    try {
        // Prepare updated item with new quantity
        const updatedItem = { quantity };

        // Call the API to update the bill item
        await axios.put(`https://api.rcb.tdrsoftware.in/api/updateBillItem/${customerId}/${tableNo}`, { index, updatedItem });

        const tableBillIndex = billItems.findIndex(item => item.tableNo === tableNo);

        if (tableBillIndex !== -1) {
            const updatedItems = [...billItems[tableBillIndex].items]; // Create a copy of the items
            const existingItem = updatedItems[index];

            if (existingItem) {
                const newQuantity = quantity;
                const rateTypeValue = Number(existingItem.rateType);
                const newPrice = !isNaN(rateTypeValue) ? newQuantity * rateTypeValue : existingItem.price;

                // Create the updated item object, preserving all existing fields
                updatedItems[index] = {
                    ...existingItem,
                    quantity: newQuantity, // Update quantity
                    price: newPrice,
                     // Update price based on the new quantity
                };
                console.log(billItems,'bill1234')

                // Create a new bill items array without mutating the existing state
                const newBillItems = billItems.map((bill, idx) => 
                    idx === tableBillIndex 
                    ? { ...bill, items: updatedItems } 
                    : bill
                );

                // Update the state with the new bill items
                setBillItems(newBillItems);
            }
        }
    } catch (error) {
        console.error("Error updating item quantity:", error.response ? error.response.data : error.message);
    }
};
const updateNote = async (customerId, tableNo, index, note) => {
  try {
    const updatedItem = { note };

    // Call the backend API to update the note
    await axios.put(`https://api.rcb.tdrsoftware.in/api/updateBillItemNote/${customerId}/${tableNo}`, { index, updatedItem });

    // Update the note in the local state
    const tableBillIndex = billItems.findIndex(bill => bill.tableNo === tableNo);

    if (tableBillIndex !== -1) {
      const tableBill = [...billItems[tableBillIndex].items];
      tableBill[index].note = note; // Update the note for the item
      const newBillItems = [...billItems];
      newBillItems[tableBillIndex] = { ...billItems[tableBillIndex], items: tableBill };
      setBillItems(newBillItems);
    }
  } catch (error) {
    console.error("Error updating note:", error);
  }
};



  // Delete an item from the bill
  const deleteItem = async (customerId, tableNo, index) => {
    try {
      await axios.delete(`https://api.rcb.tdrsoftware.in/api/deleteBillItem/${customerId}/${tableNo}/${index}`);
      const tableBillIndex = billItems.findIndex(item => item.tableNo === tableNo);

      if (tableBillIndex !== -1) {
        const newItems = billItems[tableBillIndex].items.filter((_, i) => i !== index);
        const newBillItems = [...billItems];
        newBillItems[tableBillIndex] = { ...billItems[tableBillIndex], items: newItems };
        setBillItems(newBillItems);
      }
    } catch (error) {
      console.error("Error deleting bill item:", error);
    }
  };

  // Clear bill items for a specific table
  const clearBill = async (customerId, tableNo) => {
    try {
      await axios.delete(`https://api.rcb.tdrsoftware.in/api/clearBill/${customerId}/${tableNo}`);
      setBillItems(billItems.filter(item => item.tableNo !== tableNo));
    } catch (error) {
      console.error("Error clearing bill items:", error);
    }
  };

  return (
    <BillItemsContext.Provider value={{ 
      billItems, setBillItems,
      fetchTableBillItems, 
      addToBill, 
      updateQuantity, 
      deleteItem, 
      clearBill ,updateNote
    }}>
      {children}
    </BillItemsContext.Provider>
  );
};

export default BillItemsContext;
