import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Select,
    Box,
    Flex,Image,
    Text,Button,Card,CardBody,SimpleGrid,
    Textarea,
    Checkbox,useDisclosure
  } from "@chakra-ui/react";
  import { toast } from "react-hot-toast";
  import { useSelector } from "react-redux";
  
  import axios from 'axios'
  import "react-calendar/dist/Calendar.css";
  import Calendar from "react-calendar";
  import React, { useState,useEffect } from "react";
  import WineTypeModal from "./wineTypeModal";
  import { CiCirclePlus } from "react-icons/ci";
import { FaPlus } from "react-icons/fa";
  
 
  
  const WineRegister = () => {
    // const [data1, setData1] = useState(new Date());
    // console.log(data1.toDateString());
    // const [data2, setData2] = useState(new Date());
    // console.log(data2.toDateString());
    // const [total, setTotal] = useState(0);
    // const [advance, setAdvance] = useState(0);
    //let dueAmount = 0;
    const {isOpen,onOpen,onClose}=useDisclosure()
    const [type,setType]=useState([])
    const [dueAmount, setDueAmount] = useState(0); 
    const [data,setData]=useState([])
    const [selectedFile, setSelectedFile] = useState(null);
  const[imageURL,setImageURL]=useState(null);
  const [uploading, setUploading] = useState(false);
  const [images,setImages]=useState([])
 const customerId=localStorage.getItem('customerId')
    const handlePost = async () => {
      try {
        setUploading(true);
        const formData = new FormData();
        formData.append('customerId', customerId);
        formData.append('image', selectedFile);  // Ensure the field name is 'image' here
    
        const response = await axios.post("https://api.rcb.tdrsoftware.in/images", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        const data = response.data;
        setImageURL(Object.values(data).join());
     toast.success("Image uploaded successfully");
        console.log(data,'data')
      } catch (err) {
        toast.error(err);
      } finally {
        setUploading(false);
      }
      
    };
    const handleOpen=()=>{
      onOpen()
    }
    console.log(imageURL,'imageURL')
    const fetchData=async()=>{
         try {
            const response=await axios.get(`https://api.rcb.tdrsoftware.in/api/getCategoryData/${customerId}`);
            setData(response.data.category)
            console.log(response.data.category)
         } catch (error) {
            toast.error(error.message)
         }
    }
    const fetchTypeData=async()=>{
      try {
        const response=await axios.get(`https://api.rcb.tdrsoftware.in/api/getWineTypeData/${customerId}`);
      //  console.log(response.data.type,'type')
        setType(response.data.type)
      } catch (error) {
        console.error(error.message)
      }
    }
    console.log(type,'type')
    useEffect(()=>{
         fetchData()
         fetchTypeData()
    },[customerId])
    
    const [formData, setFormData] = useState({
        itemCode:"",
        code:"",
        name:"",
        category:"",
        brand:"",
        packing:"",
        mrp:0,
        purchaseRate:0,
        acRate:0,
        acHalfRate:0,
        nonAcRate:0,
        nonAcHalfRate:0,
        netRate:0,
        closingStock:0,
        stockMl:0,
        itemType:"",
        itemPhoto:"",
        halfPlate:false
      
      
      
    });
    const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0]);
     
      
    }
      

    const validateForm = () => {
      const requiredFields = [
        "itemCode",
"code",
"name",
"category",
"brand",
"packing",
"mrp",
"purchaseRate",
"acRate",




"itemType",
        
        
        
      ];
  
      for (const field of requiredFields) {
        if (!formData[field]) {
          return false;
        }
      }
      return true;
    };

  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
     const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: checked, // Set formData.halfPlate to true/false based on checkbox state
        }));
      };
    const handleSubmit = async(e) => {
      if (!validateForm()) {
        toast.error("Please fill in all required fields.");
        return;
      }
      e.preventDefault();
      // Here you can send formData to your backend server or process it further
      console.log("Form submitted with data:", formData);
     
      // Reset form after submission
      setFormData({
        itemCode:formData.itemCode,
        code:formData.code,
        name:formData.name,
        category:formData.category,
        brand:formData.brand,
        packing:formData.packing,
        mrp:formData.mrp,
        purchaseRate:formData.purchaseRate,
        acRate:formData.acRate,
        acHalfRate:formData.acHalfRate,
        nonAcRate:formData.nonAcRate,
        nonAcHalfRate:formData.nonAcHalfRate,
        netRate:formData.netRate,
        closingStock:formData.closingStock,
        stockMl:formData.stockMl,
        itemType:formData.itemType,
      
      itemPhoto:imageURL,
      halfPlate:formData.halfPlate,
      
       
      });
    
      
    };
    const save=async(e)=>{
      if (!validateForm()) {
        toast.error("Please fill in all required fields.");
        return;
      }
      try{
        const response=await axios.post("https://api.rcb.tdrsoftware.in/api/wineRegister",{
          customerId:customerId,data:formData
        });
        console.log('Data Submitted: ',response.data);
        toast.success(response.data.message)
        setFormData({
            itemCode:"",
            code:"",
            name:"",
            category:"",
            brand:"",
            packing:"",
            mrp:0,
            purchaseRate:0,
            acRate:0,
            acHalfRate:0,
            nonAcRate:0,
            nonAcHalfRate:0,
            netRate:0,
            closingStock:0,
            stockMl:0,
            itemType:"",
            itemPhoto:"",
            halfPlate:false,
        
        
        
         
        });
      }catch(error){
        toast.error(error)
      }
    }
    
    return (
      <Box   bg='#f7f7f7 '
      columns={{ sm: 2, md: 4 }}
      spacing='8'
      p='10'
      textAlign='center'
      
      color='black' width='full' height='fit-content' overflowX='auto'  >
        <WineTypeModal onOpen={onOpen} isOpen={isOpen} onClose={onClose} customerId={customerId} />
        
        <FormControl isRequired onSubmit={handleSubmit}>
          <Box display="grid"  gridGap={1} >
            <Flex
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
             
            >
              <Box width="40%"  display="flex" alignItems="center" boxShadow='xl'
              borderRadius="5px"
              
              p={4}>
                <FormLabel  >Item Code</FormLabel>
                <Input w='70%' bg='#FAFAFA ' placeholder="Enter  Item Code "  border='1px solid #ffe4d9' name='itemCode' value={formData.itemCode} onChange={handleInputChange} />
              </Box>
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Short Code</FormLabel>
                <Input w='70%' bg='#FAFAFA ' border='1px solid #ffe4d9' type="text" placeholder="Enter Short Code" name='code' value={formData.code} onChange={handleInputChange} />
              </Box>
            </Flex>
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="40%" display="flex" alignItems="center" boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Item Name</FormLabel>
                <Input w='70%' bg='#FAFAFA '  border='1px solid #ffe4d9' placeholder="Enter Item Name" name='name' value={formData.name} onChange={handleInputChange}/>
                 
                 
                
              </Box>
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Category</FormLabel>
                <Select w='70%'  bg='#FAFAFA ' border='1px solid #ffe4d9' placeholder="Enter Category" name='category' value={formData.category} onChange={handleInputChange} >
                    {data?.map((category,index)=>(
                        <option key={index} value={category.categoryName} >{category.categoryName}</option>
                    ))}
                </Select>
                  
                  
              </Box>
              
             
            </Box>
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
                <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Brand Name</FormLabel>
                <Input w='70%'  bg='#FAFAFA ' border='1px solid #ffe4d9' placeholder="Enter Brand Name" name='brand' value={formData.brand} onChange={handleInputChange} />
                  
                  
              </Box>
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Packing</FormLabel>
                <Input w='70%'  bg='#FAFAFA ' border='1px solid #ffe4d9' placeholder="Enter Packing" name='packing' value={formData.packing} onChange={handleInputChange}/>
              </Box>
              
            </Box>
            {/* <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
               
              <Box width="60%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Room Types</FormLabel>
                <Textarea w='80%'  bg='#FAFAFA ' placeholder="Room Types" name='roomType' value={formData.roomType} onChange={handleInputChange}/>
              </Box>
              
            </Box> */}
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
                <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>MRP</FormLabel>
                <Input w='70%'  bg='#FAFAFA ' border='1px solid #ffe4d9' placeholder="Enter Mrp" name='mrp' value={formData.mrp} onChange={handleInputChange} />
                  
                  
              </Box>
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Purchase Rate</FormLabel>
                <Input w='60%'  bg='#FAFAFA ' border='1px solid #ffe4d9' placeholder="Enter Purchase Rate" name='purchaseRate' value={formData.purchaseRate} onChange={handleInputChange}/>
              </Box>
              
            </Box>
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
                <Box width="40%" display="flex" alignItems="center" justifyContent='center'  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Half Peg Available?</FormLabel>
             <Checkbox onChange={handleCheckboxChange} name='halfPlate' value={formData.halfPlate}/>
                  
                  
              </Box>
            
              
            </Box>

            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
                <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Ac Full Rate</FormLabel>
                <Input w='60%'  bg='#FAFAFA ' border='1px solid #ffe4d9' placeholder="Ac Full Rate" name='acRate' value={formData.acRate} onChange={handleInputChange} />
                  
                  
              </Box>
              {formData.halfPlate ? <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Ac Half Rate</FormLabel>
                <Input w='60%'  bg='#FAFAFA ' border='1px solid #ffe4d9' placeholder="Ac Half Rate" name='acHalfRate' value={formData.acHalfRate} onChange={handleInputChange}/>
              </Box> : ""}
              
            </Box>
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
                <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Non-Ac Full Rate</FormLabel>
                <Input w='60%'  bg='#FAFAFA ' border='1px solid #ffe4d9' placeholder="Non-ac full rate" name='nonAcRate' value={formData.nonAcRate} onChange={handleInputChange} />
                  
                  
              </Box>
              {formData.halfPlate ? <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Non-Ac Half Rate</FormLabel>
                <Input w='60%'  bg='#FAFAFA ' border='1px solid #ffe4d9' placeholder="Non-ac half rate" name='nonAcHalfRate' value={formData.nonAcHalfRate} onChange={handleInputChange}/>
              </Box> : ""}
              
            </Box>
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
                <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Net Rate</FormLabel>
                <Input w='60%'  bg='#FAFAFA ' border='1px solid #ffe4d9' placeholder="Enter Net Rate" name='netRate' value={formData.netRate} onChange={handleInputChange} />
                  
                  
              </Box>
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Closing Stock</FormLabel>
                <Input w='60%'  bg='#FAFAFA ' border='1px solid #ffe4d9' placeholder="Enter Closing Stock" name='closingStock' value={formData.closingStock} onChange={handleInputChange}/>
              </Box>
              
            </Box>
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
                 <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Stock Ml</FormLabel>
                <Input w='50%' bg='#FAFAFA ' border='1px solid #ffe4d9' placeholder="Enter Stock" name='stockMl' value={formData.stockMl} onChange={handleInputChange} />
              </Box>
                <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Item Type</FormLabel>
                <Select w='60%' bg='#FAFAFA ' border='1px solid #ffe4d9' placeholder="Item Type" name='itemType' value={formData.itemType} onChange={handleInputChange} >
                {type?.map((data, index) => (
                <option key={data._id} value={data.name}>
                {data.name}
                 </option>
                  ))}
                </Select>

                <Button ml={2} size='sm' colorScheme="blue" onClick={handleOpen}><FaPlus/></Button>
              </Box>
              
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Item Photo</FormLabel>
                <Input w='50%' type="file" bg='#FAFAFA ' border='1px solid #ffe4d9' onChange={handleFileChange}  />
                <Button variant='solid' onClick={handlePost} disabled={uploading}>
        {uploading ? "Uploading..." : "Upload"}
      </Button>
                
              </Box>
             
            </Box>
            
            
           
             
             
           
            
           
            
            
          <hr/>
          

          </Box>
         
          <Button type="submit" variant='solid' mr={4} color='white' bg='tomato' _hover={{color:'tomato',bg:'white'}} mt={5} onClick={handleSubmit}>Submit</Button>
          <Button type="submit" variant='solid' color='white' bg='tomato' _hover={{color:'tomato',bg:'white'}} mt={5} onClick={save}>Save</Button>
        </FormControl>
        
      </Box>
    );
  };
  
  export default WineRegister;
  