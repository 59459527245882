import React, { useState, useEffect } from "react";
import {
  Box, FormControl, FormLabel, Input, Select, Button, List, ListItem,
  TableContainer, Table, Thead, Tbody, Tr, Th, Td,Flex,TableCaption
} from "@chakra-ui/react";
import axios from "axios";
import { toast } from "react-hot-toast";
import { FaTrash } from "react-icons/fa";

const StockTransfer = () => {
  const customerId = localStorage.getItem("customerId");

  const [store, setStore] = useState([]);
  
  const [staff, setStaff] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [storeName, setStoreName] = useState('GoDown');
  const [searchTerm1,setSearchTerm1]=useState('')
  const [filteredData,setFilteredData]=useState([])
  const [transfer,setTransfer]=useState([])
  const [name,setName]=useState('')
  const [data, setData] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1); // Track selected item by keyboard

  const [formData, setFormData] = useState({
    fromStore: "",
    toStore: "",
    transferBy: "",
    docNo: "",
    transferDate: "",
    transferNo:"",
    items: []
  });

  const [itemFormData, setItemFormData] = useState({
    itemName: "",
    quantity: 0,
    unit:'',
    
  });
  const fetchData2 = async () => {
    try {
      const response = await axios.get(`https://api.rcb.tdrsoftware.in/api/getStoreItems/${customerId}/${storeName}`);
      // Check if items exist in the response
      if (response.data.items.length > 0) {
        setData(response.data.items);
        setFilteredData(response.data.items);
       // toast.success(response.data.message);
      } else {
        // If no items found, set data and filteredData to empty arrays
        setData([]);
        setFilteredData([]);
        toast.error('No items found in this store');
      }
    } catch (error) {
      toast.error('Failed to fetch data');
    }
   
    
  };

  // Fetch store, staff, and item data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [storeRes, staffRes, itemRes,transferRes] = await Promise.all([
          axios.get(`https://api.rcb.tdrsoftware.in/api/store/${customerId}`),
          axios.get(`https://api.rcb.tdrsoftware.in/api/staff/${customerId}`),
          axios.get(`https://api.rcb.tdrsoftware.in/api/item/${customerId}`),
          axios.get(`https://api.rcb.tdrsoftware.in/api/getTransfer/${customerId}`)
        ]);

        setStore(storeRes.data);
        setStaff(staffRes.data.staff);
        setItemData(itemRes.data.item);
        setTransfer(transferRes.data.transfers)
      } catch (error) {
        toast.error("Error fetching data");
      }
    };
    fetchData();
    
  }, [customerId]);
  useEffect(() => {
    fetchData2(storeName);
  }, [storeName]);

  // Handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    const filtered = itemData.filter(item =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredItems(filtered);
    setSelectedIndex(-1); // Reset selected index on new search
  };
  const handleSearchChange1 = (event) => {
    setSearchTerm1(event.target.value);
    filterData(event.target.value );
  };
  const filterData = (term) => {
    let filtered = data;

    // Filter by search term (phone number or registration)
    if (term) {
      filtered = filtered.filter(item =>
        item.itemName.toLowerCase().includes(term.toLowerCase())
      );
    }
    setFilteredData(filtered)
}
let latestBillNo;
for(let i=transfer.length-1;i>=0;i--){
if(transfer[i].transferNo){
  latestBillNo=transfer[i].transferNo;
  break;
}

}
 const generateBillNo = (latestBillNo) => {
  const year = new Date().getFullYear(); // Get the current year
  const prefix = `T-${year}-`;

  // If there is a latest bill number, extract the numeric part and increment it
  if (latestBillNo) {
    const latestNumber = parseInt(latestBillNo.split("-")[2], 10); // Get the last part of the billNo
    const newNumber = latestNumber + 1;
    return `${prefix}${newNumber}`; // Increment and return the new bill number
  }

  // If no previous bill number, start from '001'
  return `${prefix}1`;
};



  // Handle item selection via click or Enter key
  const handleSelectItem = (item) => {
    setItemList(prev => [...prev, { itemName: item.name, quantity: 0,unit:item.unit }]);
    setSearchTerm("");
    setFilteredItems([]);
  };

  // Handle keyboard navigation in search list
  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setSelectedIndex(prev => (prev < filteredItems.length - 1 ? prev + 1 : prev));
    } else if (e.key === "ArrowUp") {
      setSelectedIndex(prev => (prev > 0 ? prev - 1 : prev));
    } else if (e.key === "Enter" && selectedIndex >= 0) {
      handleSelectItem(filteredItems[selectedIndex]);
    }
  };

  // Update item list on change (quantity, unit)
  const handleItemChange = (index, field, value) => {
    const updatedItemList = [...itemList];
    updatedItemList[index][field] = value;
    setItemList(updatedItemList);
    setFormData(prev => ({ ...prev, items: updatedItemList }));
  };

  const handleDelete = (index) => {
    const newItems = itemList.filter((_, i) => i !== index);
    setItemList(newItems);
    setFormData(prev => ({ ...prev, items: newItems }));
  };
  const handleChange = (e) => {
    const selectedStoreName = e.target.value;
    setStoreName(selectedStoreName);
  };

  // Form input change handler
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  useEffect(() => {
    const newBillNo = generateBillNo(latestBillNo); // Generate new bill number
    setFormData((prevData) => ({ ...prevData, transferNo: newBillNo }));
  }, [latestBillNo]); 

  // Submit form handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      toast.error("Please fill in all required fields.");
      return;
    }

    // Submit the form
    saveForm();
  };

  const validateForm = () => {
    const requiredFields = ["fromStore", "toStore", "transferBy", "docNo", "transferDate", "items"];
    for (const field of requiredFields) {
      if (!formData[field] || (Array.isArray(formData[field]) && formData[field].length === 0)) {
        return false;
      }
    }
    return true;
  };
  //const yum = parseInt(itemData.find(i => i.name === 'egg')?.equivalentNo.match(/=\s*(\d+)/)?.[1]);
  
 // console.log(yo,'yo',itemData)

//console.log(yo); // Output: "10kg 750gram"

//console.log(yo)

  const saveForm = async () => {
    try {
      // Construct payload from formData
      const payload = {
        customerId: customerId,
        transferNo: formData.transferNo,
        fromStore: formData.fromStore,
        toStore: formData.toStore,
        transferBy: formData.transferBy,
        docNo: formData.docNo,
        transferDate: formData.transferDate,
        items: formData.items, // List of items with name and quantity
      };
  
      // Send POST request with payload
      const response = await axios.post(
        "https://api.rcb.tdrsoftware.in/api/stockTransferByUnit", 
        payload // Pass the constructed payload
      );
  
      toast.success(response.data.message);
  
      // Reset form after successful submission
      setFormData({
        fromStore: "",
        toStore: "",
        transferBy: "",
        docNo: "",
        transferDate: "",
        items: [],
        transferNo: "",
      });
      setItemList([]);
      window.location.reload() // Reset item list as well
    } catch (error) {
      toast.error(error.response?.data?.message || "Error saving data");
    }
  };
  
  console.log(formData,'formdata');
  return (
    <Box bg="#EAE5DF" p="10" width="full" height="fit-content" overflowX="auto">
     <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' mt={4} mb={4} p={4} gap={10}>
      <Input
      bg='#FAFAFA'
          w='30%'
          type="text"
          placeholder="Search Item name"
          value={searchTerm1}
          onChange={handleSearchChange1}
        />
      <Select w='30%' bg='#FAFAFA' name='storeName' value={storeName} onChange={handleChange} >
        {store.map((store)=>(
            <option onClick={()=>fetchData2(storeName)}>{store.name}</option>
        ))}
      </Select>
      </Box>
      <TableContainer width='full'>
        <Table size='sm' variant='striped' colorScheme='yellow'>
          <TableCaption>Store Data</TableCaption>
          <Thead>
            <Tr>
              <Th>Item Name</Th>
              {/* <Th>Purchase Rate</Th> */}
              <Th>Quantity</Th>
              <Th>Stock</Th>
              <Th>Unit</Th>
              

              {/* <Th>Unit</Th>
              <Th>Sgst</Th>
              <Th>Cgst</Th>
              <Th>Amount</Th> */}
             
            </Tr>
          </Thead>
          <Tbody>
          {filteredData && filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <Tr key={index}>
                  <Td>{item.itemName}</Td>
                 
                  <Td>{item.quantity}</Td>
                  <Td>{
    Math.floor(item.quantity) + " " +itemData.find(i => item.itemName === i.name)?.purchaseUnit+" "+
    (
     Math.floor( (item.quantity - Math.floor(item.quantity)) *
      parseInt(itemData.find(i => item.itemName === i.name)?.equivalentNo?.match(/=\s*(\d+)/)?.[1] || 0))
    ) + " " +
    itemData.find(i => item.itemName === i.name)?.usageUnit
  }</Td>
                  
                  <Td>{item.unit}</Td>
                 
                  
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={7} textAlign="center">
                  No items found in this store
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <FormControl isRequired onSubmit={handleSubmit}>
        {/* Department and staff selection code */}
        {/* Search box with keyboard functionality */}
        <Box display="grid" gridGap={1}>
          <Flex
            display="flex"
            gap="10px"
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Box
              width="40%"
              size='sm'
              display="flex"
              alignItems="left"
              flexDirection='column'
              border='1px solid #d7fff7'
              bg='white'
              borderRadius="5px"
              p={4}
            >
              <FormLabel fontSize='sm'>Department From</FormLabel>
              <Select
                w="100%"
                size='sm'
                bg="#FAFAFA "
                placeholder="Department From"
                name="fromStore"
                value={formData.fromStore}
                onChange={handleInputChange}
              >
                {store?.map((item,index) => (
                  <option key={index}>{item.name}</option>
                ))}
              </Select>
            </Box>
            <Box
              width="40%"
              display="flex"
              alignItems="left"
              boxShadow="xl"
              border='1px solid #d7fff7'
              bg='white'
              flexDirection='column'
              borderRadius="5px"
              p={4}
            >
              <FormLabel fontSize='sm'>Department To</FormLabel>
              <Select
                w="100%"
                size='sm'
                bg="#FAFAFA "
                type="text"
                placeholder="Department To"
                name="toStore"
                value={formData.toStore}
                onChange={handleInputChange}
              >
                {store?.map((item,index) => (
                  <option key={index}>{item.name}</option>
                ))}
              </Select>
            </Box>
            <Box
              width="30%"
              display="flex"
              alignItems="left"
              flexDirection='column'
              border='1px solid #d7fff7'
              bg='white'
             
              borderRadius="5px"
              p={4}
            >
              <FormLabel>TransferBy</FormLabel>
              <Select
                w="100%"
                size='sm'
                bg="#FAFAFA "
                placeholder="Transfer By"
                name="transferBy"
                value={formData.transferBy}
                onChange={handleInputChange}
              >
                {staff?.map((item,index) => (
                  <option key={index}>{item.name}</option>
                ))}
              </Select>
            </Box>
            <Box
              width="30%"
              display="flex"
              alignItems="left"
              flexDirection='column'
              border='1px solid #d7fff7'
              bg='white'
              borderRadius="5px"
              p={4}
            >
              <FormLabel>Doc No</FormLabel>
              <Input
                w="100%"
                size='sm'
                bg="#FAFAFA "
                placeholder="Doc No"
                name="docNo"
                value={formData.docNo}
                onChange={handleInputChange}
              />
            </Box>
            <Box
              width="30%"
              display="flex"
              alignItems="left"
              flexDirection='column'
              border='1px solid #d7fff7'
              bg='white'
              borderRadius="5px"
              p={4}
            >
              <FormLabel>Transfer No</FormLabel>
              <Input
                w="100%"
                size='sm'
                bg="#FAFAFA "
                placeholder="Transfer No"
                name="transferNo"
                value={formData.transferNo}
                onChange={handleInputChange}
              />
            </Box>
            <Box
              width="30%"
              display="flex"
              alignItems="left"
              flexDirection='column'
              border='1px solid #d7fff7'
              bg='white'
              borderRadius="5px"
              p={4}
            >
              <FormLabel>Transfer Date</FormLabel>
              <Input
              type='date'
                w="100%"
                size='sm'
                bg="#FAFAFA "
                placeholder="Transfer Date"
                name="transferDate"
                value={formData.transferDate}
                onChange={handleInputChange}
              />
            </Box>
          
          </Flex>
          

          <hr />
        </Box>
        <Box mt={4}>
          <FormLabel>Search Item</FormLabel>
          <Input
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
            placeholder="Enter Item Name"
            bg="white"
            size="sm"
          />
          {filteredItems.length > 0 && (
            <List mt={2} bg="white" borderRadius="md" p={2}>
              {filteredItems.map((item, index) => (
                <ListItem
                  key={index}
                  p={2}
                  cursor="pointer"
                  bg={selectedIndex === index ? "#EAEAEA" : "white"}
                  onClick={() => handleSelectItem(item)}
                  _hover={{ bg: "#EAEAEA" }}
                >
                  {item.name}
                </ListItem>
              ))}
            </List>
          )}
        </Box>

        {/* Table to show selected items */}
        <TableContainer mt={10} bg="white" borderRadius="10px" p={4}>
          <Table size="sm" variant="striped">
            <Thead>
              <Tr>
                <Th>Sl No</Th>
                <Th>Item Name</Th>
                <Th>Quantity</Th>
                <Th>Unit</Th>
                
                <Th>Delete</Th>
              </Tr>
            </Thead>
            <Tbody>
              {itemList.map((item, index) => (
                <Tr key={index}>
                  <Td>{index + 1}</Td>
                  <Td>{item.itemName}</Td>
                  <Td>
                    <Input
                      width="60%"
                      type='Number'
                      placeholder="Enter Quantity"
                      size="sm"
                      value={item.quantity}
                      onChange={(e) => handleItemChange(index, "quantity", parseInt(e.target.value))}
                    />
                  </Td>
                  <Td>
                    <Select
                      width="80%"
                      placeholder="Enter Unit"
                      size="sm"
                      value={item.unit}
                      onChange={(e) => handleItemChange(index, "unit", e.target.value)}
                    >
                     { itemData.filter((i)=>{return item.itemName === i.name}).flatMap((i)=>{return [i.purchaseUnit,i.usageUnit]})?.map((i,index)=><option key={index} value={i}>{i}</option>)}
                    </Select>
                  </Td>
                  <Td>
                    <Button colorScheme="red" onClick={() => handleDelete(index)}>
                      <FaTrash />
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>

        {/* Submit and Save buttons */}
        <Box display='flex' justifyContent='center' alignItems='center'>
        <Button mt={5} onClick={handleSubmit} bg="#FEA116" color="#0F172B">
          Transfer
        </Button>
        </Box>
        
        {/* <Button mt={5} onClick={saveForm} bg="#FEA116" color="#0F172B">
          Save
        </Button> */}
      </FormControl>
    </Box>
  );
};

export default StockTransfer;
