import React, { useEffect, useState ,useRef} from 'react';
import axios from 'axios';
import {
  Box,
  Input,
  Table,
  TableCaption,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,Text,
  Image,useDisclosure, AlertDialogBody,
  AlertDialog,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay
} from "@chakra-ui/react";
import toast from 'react-hot-toast';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { set } from 'lodash';
import WineUpdateModal from './wineUpdateModal';

const WineData=()=>{
    const [data,setData]=useState([]);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [isOpen1,setIsOpen]=useState(false)
    const {isOpen, onOpen, onClose} = useDisclosure({});
    const [wineData,setWineData]=useState([])
    const [category,setCategory]=useState([]);
    const [Id,setId]=useState(null);
    const [type,setType]=useState([])
    const customerId=localStorage.getItem('customerId')
    const fetchData = async () => {
        const response = await axios.get(`https://api.rcb.tdrsoftware.in/api/getWineData/${customerId}`);
        const wines = response.data.wine;
    //const {isOpen, onOpen, onClose} = useDisclosure({});
        const updatedWines = await Promise.all(
          wines.map(async (wine) => {
            const imageResponse = await axios.get('https://api.rcb.tdrsoftware.in/image', {
              params: { customerId, key: wine.itemPhoto }
            });
            wine.imageUrl = imageResponse.data;
            return wine;
          })
        );
    
        setData(updatedWines);
      };
      const getWine=async(id)=>{
        
        try {
        const response=await axios.get(`https://api.rcb.tdrsoftware.in/api/getWineDataById/${customerId}/${id}`);
        setWineData(response.data.wine)
        setId(id)
        onOpen()
      } catch (error) {
        toast.error(error.message)
      }
      }
      const deleteModal=async(id)=>{
        try {
          const response=await axios.delete(`https://api.rcb.tdrsoftware.in/api/deleteWineData/${customerId}/${id}`);
          toast.success(response.data.message)
          fetchData()
        } catch (error) {
          toast.error(error.message)
        }
      }
      const cancelRef=useRef();
      const DeleteModal = (id) => {
        setDeleteItemId(id);
        setIsOpen(true);
      };
    
      const onClose1 = () => {
        setIsOpen(false);
        setDeleteItemId(null);
      };

      const categoryData=async()=>{
        try {
           const response=await axios.get(`https://api.rcb.tdrsoftware.in/api/getCategoryData/${customerId}`);
           setCategory(response.data.category)
           console.log(response.data.category)
        } catch (error) {
           toast.error(error.message)
        }
   }
   const handleDelete = async () => {
    try {
      const response = await axios.delete(`https://api.rcb.tdrsoftware.in/api/deleteWineData/${customerId}/${deleteItemId}`);
      
        toast.success('Data deleted successfully');
        onClose1()
        fetchData();
        
    } catch (error) {
      console.error('Error deleting data:', error);
      ;
      toast.error('Failed to delete data');
    }
  }
   const fetchTypeData=async()=>{
    try {
      const response=await axios.get(`https://api.rcb.tdrsoftware.in/api/getWineTypeData/${customerId}`);
    //  console.log(response.data.type,'type')
      setType(response.data.type)
    } catch (error) {
      console.error(error.message)
    }
  }


      console.log(data,'data');
      const categories=category.map((item)=>item.categoryName);
      const types=type.map((item)=>item.name)
      console.log(categories,'categories')

      useEffect(()=>{
        fetchData()
        categoryData()
        fetchTypeData()
      },[customerId])

      return (
        <Box width='100%' overflow='scroll'>
        <WineUpdateModal isOpen={isOpen} onClose={onClose} customerId={customerId} onOpen={onOpen} category={categories} type={types} data={wineData} id={Id} setSelectId={setId} />
            <TableContainer width='full'>

        <Table size='sm' variant='striped' colorScheme='green'>
          <TableCaption>Wine Data</TableCaption>
          <Thead>
            <Tr>
              <Th>Item Name</Th>
              <Th>Item Code</Th>
              <Th>Short Code</Th>
              <Th>Category</Th>
              <Th>Item Photo</Th>
              <Th>Item Type</Th>
              <Th>MRP</Th>
              <Th>Purchase Rate</Th>
              <Th>Net Rate</Th>
              <Th>Ac Full Rate</Th>
              <Th>Ac Half Rate</Th>
              <Th>Non-Ac Full Rate</Th>
              <Th>Non-Ac Half Rate</Th>
              <Th>Stock in Ml</Th>
              <Th>Closing  Stock</Th>
              <Th>Edit</Th>
              <Th>Delete</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((wine, index) => (
              <Tr key={index}>
                <Td>{wine.name}</Td>
                <Td>{wine.itemCode}</Td>
                <Td>{wine.code}</Td>
                <Td>{wine.category}</Td>
                <Td>
                    <Image src={wine.imageUrl} w='100' h='100'/>
                    </Td>
                <Td>{wine.itemType}</Td>
                <Td>{wine.mrp}</Td>
                <Td>{wine.purchaseRate}</Td>
                <Td>
                 {wine.netRate}
                </Td>
                <Td>{wine.acRate}</Td>
                <Td>{wine.acHalfRate}</Td>
                <Td>{wine.nonAcRate}</Td>
                <Td>{wine.nonAcHalfRate}</Td>
                <Td>{wine.stockMl}</Td>
                <Td>{wine.closingStock}</Td>
                <Td> <Button colorScheme='green' onClick={() => getWine(wine._id)}><FaEdit/></Button></Td>
                <Td> <Button colorScheme='red' onClick={() => DeleteModal(wine._id)}><FaTrash/></Button></Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <AlertDialog isOpen={isOpen1} leastDestructiveRef={cancelRef} onClose={onClose1}>
      <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Deletion
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure you want to delete this item?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose1}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
        </Box>
      )
}
export default WineData