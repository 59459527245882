import React,{useEffect,useState} from 'react';
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Select,
    Box,
    Flex,Image,
    Text,Button,Card,CardBody,SimpleGrid,
    Textarea,Checkbox, CheckboxGroup,useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    
  } from "@chakra-ui/react";
  import { IoMdClose } from "react-icons/io";
import axios from 'axios'
import toast from 'react-hot-toast';

  const FoodCategoryModal=({onOpen,isOpen,onClose,customerId})=>{
    const [formData, setFormData] = useState({
        name: ""
    })
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
      const save=async()=>{
        try {
            const response=await axios.post(`https://api.rcb.tdrsoftware.in/api/foodCategoryRegister`,{customerId:customerId,data:formData})
            toast.success(response.data.message)
            setFormData({
                name:""
            })
            window.location.reload()
        
        } catch (error) {
            toast.error(error.message)
        }
      }

     

    return (
        <Modal isOpen={isOpen} onClose={onClose} width='full' size='md'   >
      <ModalOverlay />
      <ModalContent bg='#f9f1e3'>
        <ModalHeader display='flex' justifyContent='space-between' alignItems='center'>
            <Text>Food Category </Text>
            <Button colorScheme='red' onClick={onClose} ><IoMdClose/></Button></ModalHeader>
       
        <ModalBody >
           <FormControl>
            <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
            <FormLabel>Category</FormLabel>
            <Input bg='white' name='name' placeholder='Enter Category Name' value={formData.name} onChange={handleInputChange} />
            </Box>
            <Box display='flex' justifyContent='center' alignItems='center' mt={3}>
            <Button colorScheme='blue' size='sm' onClick={save}> Save</Button>
            </Box>
           </FormControl>
        </ModalBody>
        <ModalFooter>
         
         </ModalFooter>
       </ModalContent>
     </Modal>
    )
  }
  export default FoodCategoryModal