import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import {
  Box,
  VStack,
  Button,
  SimpleGrid,
  Stack,
  Text,
  Card,
  CardBody,
  Image,
  Flex,
  Input,
  CardFooter,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import { FaEdit, FaRupeeSign, FaTrash } from "react-icons/fa";
import toast from 'react-hot-toast';
import FoodUpdateModal from './updateFoodModal';

const FoodData106 = () => {
  const [data, setData] = useState([]);
  const [food, setFood] = useState([]);
  const [selectId, setSelectId] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [typeFilter, setTypeFilter] = useState('All');
  const [nameFilter, setNameFilter] = useState('All');
  const [uniqueNames, setUniqueNames] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); 
  const [isOpen1, setIsOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const cancelRef = useRef();

      const customerId=106

  const fetchData = async () => {

    const response = await axios.get(`https://api.rcb.tdrsoftware.in/api/getFoodData/${customerId}`);
    const rooms = response.data.food;
    const updatedRooms = await Promise.all(
      rooms.map(async (room) => {
        const imageResponse = await axios.get('https://api.rcb.tdrsoftware.in/image', {
          params: { customerId, key: room.image }
        });
        room.imageUrl = imageResponse.data;
        return room;
      })
    );
    setData(updatedRooms);
    setFilteredData(updatedRooms);
    setUniqueNames(['All', ...new Set(updatedRooms.map(item => item.category))]);
  };

  const DeleteModal = (id) => {
    setDeleteItemId(id);
    setIsOpen(true);
  };

  const onClose1 = () => {
    setIsOpen(false);
    setDeleteItemId(null);
  };

  const handleTypeFilterChange = (filter) => {
    setTypeFilter(filter);
    filterData(filter, nameFilter, searchQuery);
  };

  const handleNameFilterChange = (filter) => {
    setNameFilter(filter);
    filterData(typeFilter, filter, searchQuery);
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    filterData(typeFilter, nameFilter, query);
  };

  const { onOpen, isOpen, onClose } = useDisclosure();

  const filterData = (type, name, search) => {
    let filtered = data;

    if (type !== 'All') {
      filtered = filtered.filter(item => item.type === type);
    }

    if (name !== 'All') {
      filtered = filtered.filter(item => item.category === name);
    }

    if (search) {
      const lowerCaseSearch = search.toLowerCase();
      filtered = filtered.filter(item => 
        item.name.toLowerCase().includes(lowerCaseSearch) || 
        item.code.toLowerCase().includes(lowerCaseSearch)
      );
    }

    setFilteredData(filtered);
  };

  const updateFood = async (id) => {
    try {
      const response = await axios.get(`https://api.rcb.tdrsoftware.in/api/getFoodDataById/${customerId}/${id}`);
      setFood(response.data.food);
      setSelectId(id);
      onOpen();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`https://api.rcb.tdrsoftware.in/api/deleteFoodData/${customerId}/${deleteItemId}`);
      toast.success('Data deleted successfully');
      onClose1();
      fetchData();
    } catch (error) {
      console.error('Error deleting data:', error);
      toast.error('Failed to delete data');
    }
  };

  useEffect(() => {
    fetchData();
  }, [customerId]);

  return (
    <Box>
      {/* Search Input */}
      <Box display="flex" justifyContent="center" marginBottom="20px" gap="10px" mt={10}>
        <Input 
          placeholder="Search by Name" 
          value={searchQuery} 
          onChange={handleSearchChange} 
          width={{ base: "90%", md: "300px" }} // Responsive width
        />
      </Box>

      {/* Type Filter Buttons */}
      <Box display="flex" justifyContent="center" marginBottom="20px" gap="10px">
        {['All', 'Veg', 'Non-Veg'].map(filter => (
          <Button 
            key={filter} 
            onClick={() => handleTypeFilterChange(filter)} 
            colorScheme={typeFilter === filter ? 'green' : 'gray'}
            size={{ base: "sm", md: "md" }} // Responsive button size
          >
            {filter}
          </Button>
        ))}
      </Box>

      {/* Name Filter Buttons */}
      <SimpleGrid columns={{ base: 2, md: 6 }} gap="10px">
        {uniqueNames.map((name, index) => (
          <Button 
            key={index} 
            onClick={() => handleNameFilterChange(name)} 
            colorScheme={nameFilter === name ? 'green' : 'gray'}
            size={{ base: "sm", md: "md" }} // Responsive button size
          >
            {name}
          </Button>
        ))}
      </SimpleGrid>

      <FoodUpdateModal onClose={onClose} isOpen={isOpen} customerId={customerId} onOpen={onOpen} unique={uniqueNames} setSelectId={setSelectId} id={selectId} data={food} />

      {/* Display Filtered Data */}
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 5 }} placeItems='center' height='fit-content' gap='5px' p={2}>
        {filteredData.map((item, index) => (
          <Card maxW='sm' key={index} boxShadow='dark-lg' mb={3} width={{ base: "90%", md: "auto" }}>
            <CardBody fontFamily='sans-serif'>
              <Image
                src={item.imageUrl}
                alt={item.roomNo}
                borderRadius='lg'
                width='100%'
                height='150px'
                objectFit='cover'
              />
              <Stack mt='6' spacing='2'>
                <Box display='flex' flexDirection='column' justifyContent='space-between' textAlign='left' alignItems='left' p={2}>
                  <Box display='flex' justifyContent='center' alignItems='center' mb={2}>
                    <Text fontSize='md' color={item.type === "Veg" ? "green" : "red"}>{item.type}</Text>
                  </Box>
                  <Box display='flex' flexDirection='column' gap={2} justifyContent='space-between'>
                    <Text fontSize='sm'><b>Name</b>: {item.name}</Text>
                    <Text fontSize='sm'><b>Category</b>: {item.category}</Text>
                    {/* <Text fontSize='sm'><b>Code</b>: {item.code}</Text> */}
                    <Text color='blue.600' display='flex' alignItems='center' justifyContent='left'><b>Rate</b> <FaRupeeSign />{item.acRate}</Text>
                    {/* {item.nonAcRate > 0 ? <Text color='blue.600' display='flex' alignItems='center' justifyContent='left'><b>Non-Ac Rate</b> <FaRupeeSign />{item.nonAcRate}</Text> : ''}  */}
                  </Box>
                </Box>
              </Stack>
            </CardBody>
            {/* Card footer for edit and delete buttons */}
            {/* <CardFooter display='flex' justifyContent='space-between' alignItems='center'>
              <Button colorScheme='green' onClick={() => updateFood(item._id)} size={{ base: "sm", md: "md" }}><FaEdit /></Button>
              <Button colorScheme='red' onClick={() => DeleteModal(item._id)} size={{ base: "sm", md: "md" }}><FaTrash /></Button>
            </CardFooter> */}
          </Card>
        ))}
      </SimpleGrid>

      {/* Delete confirmation dialog */}
      <AlertDialog 
        isOpen={isOpen} 
        leastDestructiveRef={cancelRef} 
        onClose={onClose1}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete Food Item
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose1}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default FoodData106;
