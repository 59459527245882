import React,{useState,useEffect,useRef} from 'react';
import toast from 'react-hot-toast';
import axios from 'axios';
import {
    Box,Input,
    Button,
    Flex,
    Form, Table,
    TableCaption,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    } from '@chakra-ui/react';


const StockTransferData=()=>{
    const [data,setData]=useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState(new Date());
    
    const customerId=localStorage.getItem('customerId')
    const fetchData=async()=>{
        try {
            const response=await axios.get(`https://api.rcb.tdrsoftware.in/api/getTransfer/111?startDate=${startDate}&endDate=${endDate}`)
          //  toast.success(response.data.message);
            console.log(response)
            setData(response.data.transfers)
           
        } catch (error) {
            toast.error(error.message);
        }
    }
    console.log(data)
  
  
    useEffect(()=>{
        fetchData()
    },[customerId,startDate,endDate])
    return (
        <Box>
        <Box mt={10} display='flex' justifyContent='center' alignItems='center' gap={3} mb={10}>
           
        <Input
          w='20%'
          type="date"
          placeholder="Start Date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          mr={2}
        />
        <Input
          w='20%'
          type="date"
          placeholder="End Date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          mr={2}
        />
      </Box>
               <TableContainer width='full'>
        <Table size='sm' variant='striped' colorScheme='green'>
          <TableCaption>Food Data</TableCaption>
          <Thead>
            <Tr>
              <Th>From Store</Th>
              <Th>To Store</Th>

              <Th>TransferBy</Th>
              <Th>DocNo</Th>
              <Th>TransferNo</Th>
              <Th>Transfer Date</Th>
              <Th>Items</Th>
              
             
            </Tr>
          </Thead>
          <Tbody>
            {data?.map((item, index) => (
              <Tr key={index}>
                <Td>{item.fromStore}</Td>
                <Td>{item.toStore}</Td>
                <Td>{item.transferBy}</Td>
                <Td>{item.docNo}</Td>
                <Td>{item.transferNo}</Td>
                <Td>{item.transferDate}</Td>
                <Td><Table size='sm' variant='striped' colorScheme='blue'>
                    <TableCaption>Item Data</TableCaption>
                    <Thead>
                    <Tr>
                    <Th>Name</Th>
                    <Th>Quantity</Th>
                    <Th>Unit</Th>
                    
                   
                    </Tr>
                    </Thead>
                    <Tbody>
                    {item.items.map((item, index) => (
                    <Tr key={index}>
                    <Td>{item.itemName}</Td>
                    <Td>{item.quantity}</Td>
                    <Td>{item.unit}</Td>
                   
                    </Tr>))}
                    </Tbody>
                </Table></Td>
                

               
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
        </Box>
    )
}
export default StockTransferData
