import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  SimpleGrid,
  Box,
  Input,
  FormLabel,Select,
  InputGroup,
  InputLeftAddon,useDisclosure,
  Checkbox,
  

} from '@chakra-ui/react';
import { useState,useEffect } from 'react';
import { IoMdClose } from "react-icons/io";
import axios from 'axios'
import toast from 'react-hot-toast';
import { set } from 'lodash';

const EditQuickBillModal = ({isOpen,onClose,id,customerId,onOpen,setSelectId,data}) => {
    
    console.log(id,'id')    
    const [selectedFile, setSelectedFile] = useState(null);
    const [serverName,setServerName]=useState([])
    const[newImageURL,setnewImageURL]=useState(null);
    const [uploading, setUploading] = useState(false);
    const [formData,setFormData]=useState({
        foodItem: [],
        wineItem: [],
        
       
        name: "",
        phoneNo:"",
        billNo: "",
        tableNo: "",
        server:"",
        totalBilling:0,
        discount:0,
        discountPercentage:0,
        cash:0,
        mode:"",
        online:0,
        
  
  
       
  
        
       
    
    
    })
    
    
    

   
   
   console.log(Object.keys(data)+" :"+Object.values(data),'data')
   const serverData=async()=>{
    try {
      const response = await axios.get(`https://api.rcb.tdrsoftware.in/api/waiterList/${customerId}`);
      setServerName(response.data.waiterList);
      
    } catch (error) {
      
    }
   }
   
  useEffect(()=>{
  serverData()
  },[customerId])
    useEffect(() => {
        if (data) {
            setFormData({
               foodItem: data.foodItem || [],
               wineItem: data.wineItem || [],
               name: data.name || "",
               phoneNo:data.phoneNo || "",
               billNo: data.billNo || "",
               tableNo: data.tableNo ||"",
                server:data.server || "",
        totalBilling:data.totalBilling || 0,
        discount:data.discount || 0,
        discountPercentage:data.discountPercentage || 0,
        cash:data.cash || 0,
        mode:data.mode || "",
        online:data.online || 0,
                
            });
        }
    }, [data]);
    const scroll='inside'
   
    const handleInputChange=(e)=>{
     const {name,value}=e.target;
     setFormData({
        ...formData,
       [name] : value 
     })
    }
    useEffect(()=>{
      setFormData({
        ...formData,
        totalBilling:cashDiscount
      })

    },[formData.foodItem,formData.wineItem,formData.discount,formData.discountPercentage])
    const handleFoodItemChange = (index, field, value) => {
      const updatedFoodItems = formData.foodItem.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      );
      setFormData({ ...formData, foodItem: updatedFoodItems });
    };
    console.log(data.gst,'gst')
    const totalfoodBiiling = formData.foodItem?.reduce((total, item) => parseInt(total) + parseInt(item.price) , 0);
    const totalwineBiiling = formData.wineItem?.reduce((total, item) => parseInt(total) + parseInt(item.price) , 0);
    const totalBilling = (data.gst == false?(totalfoodBiiling+totalfoodBiiling*0.05):totalfoodBiiling) + totalwineBiiling;
    const cashDiscount=totalBilling-formData.discount-((formData.discountPercentage/100)*totalBilling);
  
    const handleWineItemChange = (index, field, value) => {
      const updatedWineItems = formData.wineItem.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      );
      setFormData({ ...formData, wineItem: updatedWineItems });
    };
  
    const handleDeleteItem = (type, index) => {
      if (type === 'food') {
        const updatedFoodItems = formData.foodItem.filter((_, i) => i !== index);
        toast.success('Food item deleted successfully');
        setFormData({ ...formData, foodItem: updatedFoodItems });
      } else if (type === 'wine') {
        const updatedWineItems = formData.wineItem.filter((_, i) => i !== index);
        toast.success('Wine item deleted successfully');
        setFormData({ ...formData, wineItem: updatedWineItems });
      }
    };
   
    const handleEdit = async (e) => {
      e.preventDefault();
     let totalPayment=parseInt(formData.cash)+parseInt(formData.online);
       
        if(totalPayment>formData.totalBilling){
          toast.error('Cash and Online amount should not be greater than total billing');
          return;
        }
        else if(totalPayment<formData.totalBilling){
          toast.error('Cash and Online amount should not be less than total billing');
          return;}
          
        try {
            // Make the PUT request with formData directly
            const response = await axios.put(`https://api.rcb.tdrsoftware.in/api/quickBillUpdate/${customerId}/${id}`,{data:formData});
            
            toast.success(response.data.message);
            console.log(response, 'response');
            //setSelectId(null);
            // Optionally reset the form after a successful update
            setFormData({
                foodItem: [],
                wineItem: [],
                
               
                name: "",
                phoneNo:"",
                billNo: "",
                tableNo: "",
                server:"",
                totalBilling:0,
                discount:0,
                discountPercentage:0,
                cash:0,
                mode:"",
                online:0,
            });
            window.location.reload();
    
        } catch (error) {
            toast.error("Error updating Food Data: " + error.message);
            console.error("Update failed:", error);
        }
    };
    const handleClick=()=>{
      onClose()
      setSelectId(null);
    }
  return (
    <Modal  width='full' size='3xl' isOpen={isOpen} onClose={onClose} onOpen={onOpen} scrollBehavior={scroll}  >
      <ModalOverlay />
      <ModalContent bg='#f9f1e3'>
        <ModalHeader display='flex' justifyContent='space-between' alignItems='center'>
            <Text>Bill Data- {data?.name}</Text>
            <Button colorScheme='red' onClick={handleClick} ><IoMdClose/></Button></ModalHeader>
       
        <ModalBody >
        <Box textAlign='center'>
      { formData.foodItem.length>0 ? <Text fontSize='20px' fontWeight='bold'>Food Items</Text>:""}
        {formData.foodItem?.map((item, index) => (
              <Box key={index} bg="white" p={4} borderRadius="md" display="flex" boxShadow="md" width="100%" mb={1} justifyContent="space-evenly" alignItems="center">
                <FormLabel>Name</FormLabel>
                <Input width="35%" bg="#E2DFE0" value={item.name} readOnly />
                <FormLabel>Quantity</FormLabel>
                <Input width="10%" bg="white" value={item.quantity} onChange={(e) => handleFoodItemChange(index, 'quantity', e.target.value)} />
                <FormLabel>Price</FormLabel>
                <Input width="12%" bg="white" value={item.price} onChange={(e) => handleFoodItemChange(index, 'price', e.target.value)} />
                <Button colorScheme="red" onClick={() => handleDeleteItem('food', index)}>Delete</Button>
              </Box>
            ))}
            </Box>
            <Box textAlign='center'>
           {formData.wineItem?.length>0 ? <Text fontSize='20px' fontWeight='bold'>Wine Items</Text>:""}
            {formData.wineItem?.map((item, index) => (
              <Box key={index} bg="white" p={4} borderRadius="md" display="flex" boxShadow="md" width="100%" mb={1} justifyContent="space-evenly" alignItems="center">
                <FormLabel>Name</FormLabel>
                <Input width="35%" bg="#E2DFE0" value={item.name} readOnly />
                <FormLabel>Quantity</FormLabel>
                <Input width="10%" bg="white" value={item.quantity} onChange={(e) => handleWineItemChange(index, 'quantity', e.target.value)} />
                <FormLabel>Price</FormLabel>
                <Input width="12%" bg="white" value={item.price} onChange={(e) => handleWineItemChange(index, 'price', e.target.value)} />
                <Button colorScheme="red" onClick={() => handleDeleteItem('wine', index)}>Delete</Button>
              </Box>
            ))}
            </Box>
         <SimpleGrid columns={2} gap={4} placeItems='left'>
           
           <InputGroup>
            <InputLeftAddon>Name</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='name' placeholder='Enter Name' value={formData.name} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Phone</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='phoneNo' placeholder='Enter Phone' value={formData.phoneNo} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>TableNo</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='tableNo' placeholder='Enter TableNo' value={formData.tableNo} readOnly/>
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>BillNo</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='billNo' placeholder='Enter BillNo' value={formData.billNo} readOnly/>
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Server</InputLeftAddon>
           <Select  bg='white' onChange={handleInputChange} name='server' placeholder='Enter Server' value={formData.server} >
            {serverName?.map((item,index)=>(
              <option key={index} value={item.name}>{item.name}</option>
            ))}
           </Select>
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>TotalBill</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='totalBilling' placeholder='Enter TotalBill' value={formData.totalBilling} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Discount</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='discount' placeholder='Enter Discounnt' value={formData.discount} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Discount Precentage</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='discountPercentage' placeholder='Enter DiscountPercentage' value={formData.discountPercentage} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Cash</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='cash' placeholder='Enter Cash' value={formData.cash} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Mode</InputLeftAddon>
           <Select  bg='white' onChange={handleInputChange} name='mode' placeholder='Enter Mode' value={formData.mode} >
           <option value='Phonepe'>Phonepe</option>
          <option value='GooglePe'>Googlepe</option>
          <option value="Paytm">Paytm</option>
          <option value="Upi">Upi</option>
          <option value='Card'>Card</option>
           </Select>
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Online</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='online' placeholder='Enter Online' value={formData.online} />
           </InputGroup>
           
          
          
           

         </SimpleGrid>
         <Box display='flex' alignItems='center' justifyContent='center' gap={4} mt={2}>
       
         <Button colorScheme='green' onClick={(e)=>handleEdit(e)} >Edit</Button>
         </Box>
        </ModalBody>

        <ModalFooter>
         
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditQuickBillModal;
