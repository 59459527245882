import React, { useEffect, useState } from 'react';
import { Box, VStack, Button, SimpleGrid, Stack, Text, Card, CardBody, Image, Input,CardHeader,CardFooter,Select,Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,Flex, 
  GridItem,Grid,ButtonGroup,
  OrderedList,ListItem,useDisclosure} from "@chakra-ui/react";
  import { MdArrowDropDown } from "react-icons/md";
  import { RxHalf2 } from "react-icons/rx";
import { FaPlus,FaMinus, FaRupeeSign, FaTrash, FaHome, FaPhone } from "react-icons/fa";
import axios from 'axios';
import { useTableTime } from '../context/tableTimeContext';
import { useLocation } from 'react-router-dom';

import { useReactToPrint } from "react-to-print";
import { useRef } from 'react';

import { useBillItems } from '../context/tableItemsContext';
import toast from 'react-hot-toast';
import UseKotPrint from './kot';
import { useFoodItems } from '../context/foodItemsContext';
import { numberToWords } from '../utils/numberToWords';
import HalfPlateModal from './halfPlateModal';
import UpdateKotPrint from './kotUpdate';
import { useKotContext } from '../context/kotContext';
import { FaWineBottle } from "react-icons/fa";
import FullBottleModal from './fullBottleModal';
//import { useLocation } from 'react-router-dom';

const RestaurentData = () => {
  const location = useLocation();
  const { fetchKotNo,kotNumber,serverName } = useKotContext();
  const {startTimer}=useTableTime();
  const [foodData,setFoodData]=useState([])
  const [foodData1,setFoodData1]=useState([])
  const [order,setOrder]=useState([])
  const { isOpen: isFoodModalOpen, onOpen: onOpenFoodModal, onClose: onCloseFoodModal } = useDisclosure();
const { isOpen: isWineModalOpen, onOpen: onOpenWineModal, onClose: onCloseWineModal } = useDisclosure();
console.log(kotNumber,'kotNumber')
 
 
  const handleToggle=async(id,selection)=>{
    try {
      if(selection==='Food'){
      const response=await axios.get(`https://api.rcb.tdrsoftware.in/api/getFoodDataById/${customerId}/${id}`);
      setFoodData(response.data.food)
      onOpenFoodModal()
      }else if (selection === 'Wine'){
        
          const response=await axios.get(`https://api.rcb.tdrsoftware.in/api/getWineDataById/${customerId}/${id}`);
          setFoodData(response.data.wine)
       //   console.log('wineData',response.data.wine)
          onOpenFoodModal()
      }
    } catch (error) {
      
    }
    
  }
  const handleToggle1=async(id,selection)=>{
    try {
      if(selection==='Food'){
      const response=await axios.get(`https://api.rcb.tdrsoftware.in/api/getFoodDataById/${customerId}/${id}`);
      setFoodData1(response.data.food)
      onOpenWineModal()
      }else if (selection === 'Wine'){
        
          const response=await axios.get(`https://api.rcb.tdrsoftware.in/api/getWineDataById/${customerId}/${id}`);
          setFoodData1(response.data.wine)
       //   console.log('wineData',response.data.wine)
          onOpenWineModal()
      }
    } catch (error) {
      
    }
    
  }
  const { tableNo,ac } = location.state || {};
  //console.log(tableNo,ac,'a');
  const isAc = ac === 'Ac';
  const isHalf=false; 
  const isBottle=false; 
  const { billItems, addToBill, updateQuantity, deleteItem, clearBill,fetchTableBillItems,setBillItems,updateNote} = useBillItems();
 // const {updateNote}=useBillItems()
  const {clearKotNo,clearServerName}=useKotContext()
 

  // Other state variables and functions remain the same
  const [data, setData] = useState([]);
  const [foodNote,setFoodNote]=useState('')
  const [filteredData, setFilteredData] = useState([]);
  const [wineFilterData, setWineFilterData] = useState([]);
  const [select,setSelect]=useState('gst')
  const [typeFilter, setTypeFilter] = useState('All');
  const [nameFilter, setNameFilter] = useState('All');
  //const [kotNumber,setKotNumber]=useState('');
  const [uniqueNames, setUniqueNames] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTerm1, setSearchTerm1] = useState('');
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [focusedIndex1, setFocusedIndex1] = useState(-1);
  const [note,setNote]=useState('')
  const [bill,setBill]=useState([]);
  
  const [printData,setPrintData]=useState([])
  const [quickBill,setQuickBill]=useState({
    foodItem:[],
    phoneNo:'',
    name:'',
    foodRate:0,
    tableNo:"",
    cgst:0,
    sgst:0,
    totalBilling:0,
    discount:null,
    discountPercentage:null,
    cash:null,
    mode:'',
    online:null,billNo:'',
    transactionId:"",
    cardNo:"",
    bankName:"",date:"",time:'',server:'',gst:false

  });
  const handleChangeNote=(index,e)=>{
    setFoodNote(e.target.value)
  }
  //console.log()
  const handleQuickDataChange = (e) => {
    const { name, value } = e.target;
    setQuickBill({
      ...quickBill,
      [name]: value,
    });
  };
   // Initialize useHistory hook
 // const [billItems, setBillItems] = useState([]);
  
  
  const [kot,setKot]=useState('Kot');
  const [selection, setSelection] = useState('Food');
 
  const [kotData, setKotData] = useState({
    foodItem: [],
    wineItem:[],
    tableNo:tableNo ? tableNo :'',
    kotNo:'',
    foodRate: 0,
    
    totalBilling: 0,
    
  });
 
  // const handlequantity=(e)=>{
  //   setQuantity(e.target.value)
  // }
  
  
const [wineData,setWineData]=useState([])
  const [formData, setFormData] = useState({
    foodItem: [],
    
    tableNo:tableNo ? tableNo :'',
    foodRate: 0,
    sgst: 0,
    cgst: 0,
   
    totalBilling: 0,
    
  });
  console.log(billItems,'bill')
  const foodItems=billItems.filter((item)=>{
   return item.tableNo === tableNo
  });
  //console.log(foodItems,'foodItems')
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleKotDataChange = (e) => {
    const { name, value } = e.target;
    setKotData({
      ...kotData,
      [name]: value,
    });
  };
  const listRef = useRef(null); // Ref for the <OrderedList>
  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown' && filteredData.length > 0) {
      e.preventDefault();
      const nextIndex = (focusedIndex + 1) % filteredData.length;
      setFocusedIndex(nextIndex);
      scrollIntoView(nextIndex);
    }
    else if (e.key === 'ArrowUp' && filteredData.length > 0) {
      e.preventDefault();
      const nextIndex = (focusedIndex - 1) % filteredData.length;
      setFocusedIndex(nextIndex);
      scrollIntoView(nextIndex);
    }else if (e.key === 'Enter' && focusedIndex !== -1) {
      e.preventDefault();
      handleAddToBill(filteredData[focusedIndex]);
    }
   
    
  };
  const listRef1 = useRef(null);
  const handleKeyDown1 = (e) => {
    if (e.key === 'ArrowDown' && wineFilterData.length > 0) {
      e.preventDefault();
      const nextIndex = (focusedIndex + 1) % wineFilterData.length;
      setFocusedIndex1(nextIndex);
      scrollIntoView1(nextIndex);
    }
    else if (e.key === 'ArrowUp' && wineFilterData.length > 0) {
      e.preventDefault();
      const nextIndex = (focusedIndex1 - 1) % wineFilterData.length;
      setFocusedIndex1(nextIndex);
      scrollIntoView1(nextIndex);
    }else if (e.key === 'Enter' && focusedIndex1 !== -1) {
      e.preventDefault();
      handleAddToBill(wineFilterData[focusedIndex1]);
    }
   
    
  };

  const scrollIntoView = (index) => {
    if (listRef.current && listRef.current.childNodes[index]) {
      listRef.current.childNodes[index].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  };
  const scrollIntoView1 = (index) => {
    if (listRef1.current && listRef1.current.childNodes[index]) {
      listRef1.current.childNodes[index].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  };
  const handleSelect=(e)=>{
   setSelect(e.target.value)
   setQuickBill({
    ...quickBill,
    gst:!quickBill.gst
   })
  }

  const componentRef = useRef();
const handlePrint = useReactToPrint({
  content: () => componentRef.current,
});

  const customerId = localStorage.getItem('customerId');
  const cacheImageUrl = (key, url) => {
    localStorage.setItem(`imageUrl-${key}`, url);
  };
  
  const getCachedImageUrl = (key) => {
    return localStorage.getItem(`imageUrl-${key}`);
  };
  //const items=fetchTableBillItems(customerId,tableNo)
  console.log(billItems)
 // console.log(customerId);
  const wineFetchData = async () => {
    const response = await axios.get(`https://api.rcb.tdrsoftware.in/api/getWineData/${customerId}`);
    const wines = response.data.wine;
//const {isOpen, onOpen, onClose} = useDisclosure({});
    const updatedWines = await Promise.all(
      wines.map(async (wine) => {
        const imageResponse = await axios.get('https://api.rcb.tdrsoftware.in/image', {
          params: { customerId, key: wine.itemPhoto }
        });
        wine.imageUrl = imageResponse.data;
        return wine;
      })
    );

    setWineData(updatedWines);
    setWineFilterData(updatedWines);
  };
 console.log(wineData,'wine')
 const BillData=async()=>{
  try {
    const response=await axios.get(`https://api.rcb.tdrsoftware.in/api/getQuickBillData/${customerId}`);
    setBill(response.data.quickBill)
  } catch (error) {
    toast.error(error.message);
  }

}

console.log(printData,'printData')

//console.log(quickBill,'quickBill')

 const fetchData = async () => {
  const response = await axios.get(`https://api.rcb.tdrsoftware.in/api/getFoodData/${customerId}`);
  const rooms = response.data.food;

  const updatedRooms = await Promise.all(
    rooms.map(async (room) => {
      let cachedUrl = getCachedImageUrl(room.image);
      
      if (!cachedUrl) {
        const imageResponse = await axios.get('https://api.rcb.tdrsoftware.in/image', {
          params: { customerId, key: room.image }
        });
        cachedUrl = imageResponse.data;
        cacheImageUrl(room.image, cachedUrl); // Cache the URL
      }

      room.imageUrl = cachedUrl;
      return room;
    })
  );


  const combinedData = [...updatedRooms];
  setData(combinedData);
  setFilteredData(combinedData);
  setUniqueNames(['All', ...new Set(combinedData.map((item) => item.category))]);
};

  //console.log(data)

  const fetchData3=async()=>{
    const response= await axios.get(`https://api.rcb.tdrsoftware.in/api/getPrintData/${customerId}`)
    setPrintData(response.data)
    const rooms = response.data;
    const updatedRooms = await Promise.all(
      rooms.map(async (room) => {
        const imageResponse = await axios.get('https://api.rcb.tdrsoftware.in/image', {
          params: { customerId, key: room.image }
        });
        room.imageUrl = imageResponse.data;
        return room;
      })
    );
    console.log(updatedRooms, 'updatedRooms');
    setPrintData(updatedRooms);
}
//console.log(data,'data')
  const handleTypeFilterChange = (filter) => {
    setTypeFilter(filter);
    filterData(filter, nameFilter);
  }

  const handleNameFilterChange = (filter) => {
    setNameFilter(filter);
    filterData(typeFilter, filter);
  }
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterData1(event.target.value);
  };
  const handleSearchChange1 = (event) => {
    setSearchTerm1(event.target.value);
    filterData2(event.target.value);
  };
  

  const filterData = (type, name) => {
    let filtered = selection === "Food" ? data : wineData;
    if (type !== 'All') {
      filtered = filtered.filter(item => item.type === type);
    }
    if (name !== 'All') {
      filtered = filtered.filter(item => item.category === name);
    }
    if(selection === "Food"){
    setFilteredData(filtered);}
    else{
    setWineFilterData(filtered)
    }
  }
 // console.log(filterData,'filterData')
  const filterData1 = (term) => {
    if (!term) {
      const combinedData = [...data, ...wineData];
      setFilteredData(data); // Reset filteredData to all data if search term is empty
    } else {
      const lowerCaseTerm = term.toLowerCase();
      const filtered = data.filter(item =>
        item.name.toLowerCase().includes(lowerCaseTerm) || item.code.includes(term)
      );
      setFilteredData(filtered)
    }
  };
  const filterData2 = (term) => {
    if (!term) {
      const combinedData = [...data, ...wineData];
      setWineFilterData(wineData); // Reset filteredData to all data if search term is empty
    } else {
      const lowerCaseTerm = term.toLowerCase();
      const filtered = wineData.filter(item =>
        item.name.toLowerCase().includes(lowerCaseTerm) || item.code.includes(term)
      );
      setWineFilterData(filtered)
    }
  };

  useEffect(()=>{
    BillData()
    
   // printerData()
  },[customerId])
  // Load billItems from localStorage on component mount
  useEffect(() => {
    
       wineFetchData();
       fetchData();
       fetchKotNo(customerId,tableNo)
       
       fetchTableBillItems(customerId,tableNo)
  
    fetchData3()
    
  }, [customerId,tableNo]);
  
//console.log(typeof(latestBillNo),'latestBillNo')
const resName=printData.map((item)=>{return item.name})
console.log(resName,'resName')
const restaurentName=resName.toString()
console.log(typeof(restaurentName),restaurentName,'restaurentName')
let latestBillNo;
for (let i = bill.length - 1; i >= 0; i--) {
  if (bill[i].billNo) {
    latestBillNo = bill[i].billNo;
    break;
  }
  continue;
}
console.log(latestBillNo,'latestBillNo')

const generateBillNo = (latestBillNo, resName) => {
  const year = new Date().getFullYear(); // Get the current year
  
  // Extract initials from the restaurant name
  const getInitials = (name) => {
    // Define a list of small words to ignore when generating initials
    const ignoreWords = ['and', 'of', 'the', 'in','&', 'at'];
  
    return name
      .split(' ')  // Split the name by spaces
      .filter(word => !ignoreWords.includes(word.toLowerCase())) // Exclude ignored words
      .slice(0, 2)  // Take only the first two valid words
      .map(word => word[0])  // Take the first letter of each word and convert to uppercase
      .join('');  // Join the initials to form the prefix
  };

  const prefix = `${getInitials(resName)}-${year}-${tableNo}-`; 
  // Create the prefix

  // If there is a latest bill number, extract the numeric part and increment it
  if (latestBillNo) {
    const parts = latestBillNo.split("-");
const lastPart = parts[parts.length - 1]; // Get the last part of the split string
const lastNumber = parseInt(lastPart, 10); // Get the last part of the billNo
    const newNumber = lastNumber + 1;
    return `${prefix}${newNumber}`; // Increment and return the new bill number
  }
  console.log(prefix,'prefix')

  // If no previous bill number, start from '1'
  return `${prefix}1`;
};

  
 // console.log(latestBillNo,'latestBillNo')


  // Save billItems to localStorage whenever it changes
  

  // Save billItems to localStorage whenever it changes
  
 

  const handleAddToBill = (item) => {
    // Check if the item already exists in the bill
   addToBill(customerId,item,isAc,isHalf,tableNo,isBottle)
  };
  const changeNote=(index,note)=>{
   // setNote(e.target.value)
  updateNote(customerId,tableNo,index,note)
  }

  const handleQuantityChange = (index, quantity) => {
    // Update state immediately


    // Call the API to update the backend
    updateQuantity(customerId, tableNo, index, quantity);
};

  const handleDeleteItem = (index) => {
   deleteItem(customerId,tableNo,index,)
  }
  const handleClearBill=(tableNo)=>{
  clearBill(customerId,tableNo)
  }
  
  const totalAmount = foodItems[0]?.items.reduce((acc, item) => acc + item.price, 0);
  const taxableFood=foodItems[0]?.items.filter((item)=>  'description' in item);
  const wineItems=foodItems[0]?.items.filter((item)=>  'stockMl' in item);
  const wineAmount=wineItems?.reduce((acc,item)=>  acc+item.price,0);
  const taxableAmount=taxableFood?.reduce((acc,item)=>  acc+item.price,0);
  //console.log(taxableAmount,'tax')
  let totalSum;

  let arr1 = foodItems[0]?.items.map(item => {
    return {
      name: item.name,
      quantity: item.quantity,
      price: item.price,
      unit:item.unit,
      note:item.note
    };
  });
  //console.log(Object.entires(arr1),'arr1')
let arr3=foodItems[0]?.items.filter(item=> 'description' in item).map((item=>{
  return {
    name: item.name,
    quantity: item.quantity,
    price: item.price,
    unit:item.unit,
    note:item.note

  }
}))
console.log(arr3,'arr3')
// let arr3=foodItems[0]?.items.filter(item=> item.description !== '').map((item=>{
//   return {
//     name: item.name,
//     quantity: item.quantity,
//     price: item.price,
//     unit:item.unit

//   }
// }))
  
let arr2=foodItems[0]?.items.filter(item=> 'stockMl' in item).map((item=>{
  return {
    name: item.name,
    quantity: item.quantity,
    price: item.price,
    unit:item.unit

  }
}))
// let arr2=foodItems[0]?.items.filter(item=> item.stockMl !== 0).map((item=>{
//   return {
//     name: item.name,
//     quantity: item.quantity,
//     price: item.price,
//     unit:item.unit

//   }
// }))
console.log(arr2,'arr2')
  
  
  
  const handleKotChange=(e)=>{
    setKot(e.target.value)
  }
  //setFoodItemsData(arr1)
  let rcgst;
  let rsgst;
  if(select == 'gst'){ 
    rcgst=taxableAmount*0.025;
    
    rsgst=taxableAmount*0.025;
    totalSum=(totalAmount+rcgst+rsgst).toFixed(2)
  }else{
    rcgst=0;
    
    rsgst=0;
    totalSum=totalAmount+rcgst+rsgst
  }
  //const kotPrint =UseKotPrint(arr1, tableNo, totalAmount)
 
  const kotPrint = UseKotPrint(arr1, tableNo, totalAmount,'Kitchen'); 
  const kotUpdate = UpdateKotPrint(arr1, tableNo, totalAmount,'Kitchen'); 
  const handleSelectionChange = (type) => {
    setSelection(type);
    if(selection === "Food"){
      setUniqueNames(['All', ...new Set(wineData.map(item => item.category))]);
    }else{
      setUniqueNames(['All', ...new Set(filteredData.map(item => item.category))]);
    }
  };

  // Determine which data to display
  const dataToDisplay = selection === 'Food' ? filteredData : wineFilterData;
 
  const handleSaveAndPrint = async () => {
    // Prepare data to send to backend
 
   

   // console.log(billItems)
    
      setFormData({
      foodItem: arr1,
     
      
      tableNo:kotData.tableNo,
      foodRate: totalAmount,
      cgst:rcgst,
      sgst:rsgst,
      
      totalBilling: totalSum,
  })
   // Update submitted data here
   // setFormData(updatedData)
  
  
    
  //  console.log(formData)
   
  }
  const cashDiscount=totalSum-(quickBill.discount ? quickBill.discount : 0) - (quickBill.discountPercentage ? totalSum*(quickBill.discountPercentage/100) : 0)
   const onLineFoucus=(e)=>{
    setQuickBill({
      ...quickBill,
      online:(cashDiscount-(quickBill.cash ? quickBill.cash : 0)).toFixed(2)
    })
  }
  const cashFoucus=(e)=>{
    setQuickBill({
      ...quickBill,
      cash:(cashDiscount-(quickBill.online ? quickBill.online : 0)).toFixed(2)
    })
  }
  const randomBillNumber = `${tableNo}-${Math.floor(Math.random() * 1000000)}`;
  let date;
  let time;
  function getFormattedCurrentDate() {
    const now = new Date(); // Get the current date and time
  
    // Format the date as "DD-MM-YYYY"
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // getMonth() is 0-based, so +1
    const year = now.getFullYear();
  
    // Format the time as "HH:MM AM/PM"
    let hours = now.getHours();
    let minutes = String(now.getMinutes()).padStart(2, '0'); // Add leading zero to minutes if needed
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert 24-hour time to 12-hour time, adjusting midnight to 12
   const date=`${day}-${month}-${year} `;
   const time=`${hours}:${minutes}${period.toLowerCase()}`;
  
    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}${period.toLowerCase()}`;
    
    return [formattedDate,date,time];
  }
  
  
  console.log(getFormattedCurrentDate()); // Output: "21-10-2024 12am"
  
  const newBillNo = generateBillNo(latestBillNo,restaurentName);
  const newDate=getFormattedCurrentDate()
  console.log(newBillNo,'newBillNo')
  const quickSave=(e)=>{
    setQuickBill({
      
      foodItem:arr3,
      tableNo:tableNo,
      wineItem:arr2,
      name:quickBill.name,
      phoneNo:quickBill.phoneNo,
      foodRate:totalAmount,
      cgst:rcgst,
      sgst:rsgst,
      discount:quickBill.discount,
      discountPercentage:quickBill.discountPercentage,
      cash:quickBill.cash,
      online:quickBill.online,
      totalBilling:totalSum,
      billNo:newBillNo,
      mode:quickBill.mode,
      transactionId:quickBill.transactionId,
      cardNo:quickBill.cardNo,
      bankName:quickBill.bankName,
      date:newDate[1],
      time:newDate[2],
      server:serverName,
      gst:quickBill.gst,
      })
  

  }
  console.log(Object.keys(quickBill),Object.values(quickBill),'quickBill')
  const print=()=>{
    handlePrint()
  }
  //console.log(quickBill,'quickBill')
  
 // console.log(formData,'formData')
  const save=async(e)=>{
    e.preventDefault()
   const updateFormData= {...formData,
      
      foodItem:arr3,
      tableNo:tableNo,
      wineItem:arr2,
      name:quickBill.name,
      phoneNo:quickBill.phoneNo,
      foodRate:totalAmount,
      cgst:rcgst,
      sgst:rsgst,
      discount:quickBill.discount,
      discountPercentage:quickBill.discountPercentage,
      cash:quickBill.cash,
      online:quickBill.online,
      totalBilling:totalSum,
      billNo:newBillNo,
      mode:quickBill.mode,
      transactionId:quickBill.transactionId,
      cardNo:quickBill.cardNo,
      bankName:quickBill.bankName,
      date:newDate[1],
      time:newDate[2],
      server:serverName,
      gst:quickBill.gst,
      }
    try {
      // Assuming you have a backend endpoint to send data to
      const response = await axios.post('https://api.rcb.tdrsoftware.in/api/foodBillRegister', {
        customerId:customerId,data:updateFormData}
      );
      handlePrint()
   //   console.log('Bill saved:', response.data);
      toast.success(response.data.message)
  
    //  Optionally, you can reset the form data or perform any other actions upon successful save
      setFormData({
        foodItem: [],
        registration:'',
        foodRate: 0,
        tableNo:'',
        
       
        totalBilling: 0,
      });
      //setBillItems([]);
    handleClearBill(tableNo)
      // Add any further actions upon successful save here
  
    } catch (error) {
  //    console.error('Error saving bill:', error);
      toast.error("Error in food billing")
      // Handle error scenarios
    }
  }
  console.log(quickBill.cash,quickBill.online,'quickBill')
  
  
  const qSave = async (e) => {
    e.preventDefault();
  
    // Capture the updated quickBill data
    const updatedQuickBill = {
      ...quickBill,
      foodItem: arr3,
      tableNo: tableNo,
      wineItem: arr2,
      name: quickBill.name,
      phoneNo: quickBill.phoneNo,
      foodRate: totalAmount,
      cgst: rcgst,
      sgst: rsgst,
      discount: quickBill.discount,
      discountPercentage: quickBill.discountPercentage,
      cash: quickBill.cash,
      online: quickBill.online,
      totalBilling: totalSum,
      billNo: newBillNo,
      mode: quickBill.mode,
      transactionId: quickBill.transactionId,
      cardNo: quickBill.cardNo,
      bankName: quickBill.bankName,
      date: newDate[1],
      time: newDate[2],
      server: serverName,
      gst: quickBill.gst,
    };
  
    // Check if cash or online payment details are provided
    if (updatedQuickBill.cash == null && updatedQuickBill.online == null) {
      toast.error("Please enter payment details cash or online");
      return;
    }
  
    // Update the state with new data
    setQuickBill(updatedQuickBill);
  
    try {
      // Send the captured data in the API request
      const response = await axios.post('https://api.rcb.tdrsoftware.in/api/quickBillRegister', {
        customerId: customerId,
        data: updatedQuickBill
      });
  
      toast.success(response.data.message);
  
      // Optionally reset the form data upon successful save
      setQuickBill({
        arr1: [],
        foodItem: [],
        wineItem: [],
        name: '',
        phoneNo: '',
        foodRate: 0,
        totalBilling: 0,
        tableNo: '',
        discount: null,
        discountPercentage: null,
        cash: null,
        mode: '',
        online: null,
        billNo: '',
        transactionId: '',
        cardNo: '',
        bankName: '',
        date: '',
        time: '',
        serverName: '',
        gst: false,
      });
  
      handleClearBill(tableNo);
      clearKotNo(customerId, tableNo);
      clearServerName(customerId, tableNo);
  
    } catch (error) {
      toast.error(error.message);
    }
  };
  
  let arr=Object.entries(formData)
 //console.log(filteredData,'filteredData')

  return (

    <Box display='flex' flexDirection='column'>
   
      
      
    {selection === 'Food' ? <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'  >
     <Input w='40%'
        type="text"
        variant='ghost'
        bg='#F8FAF1 '
        mt={5}
       
        border='1px solid #E8E8E6'
        placeholder="Search by Name  or Code"
        value={searchTerm}
        onChange={handleSearchChange}
        onKeyDown={handleKeyDown} 
        
         // Adds margin bottom for spacing
      /> 
      
        
      <OrderedList  width='60%'  display='flex' flexDirection='column' justifyContent='center' alignItems='center'  height='fit-content' ref={listRef} tabIndex={-1}    >
        
      {searchTerm !== "" ? filteredData.map((item,index)=>(
           <ListItem width='69%' as='i' bg={index === focusedIndex ? 'red' : 'transparent'} 
           color={index === focusedIndex ? 'white' : 'black'}  p={2} mb={1} borderRadius='6px' key={item._id} onClick={() => handleAddToBill(item)} >{item.name}</ListItem>
      )) : (<></>)}
      </OrderedList> </Box>:<Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'  >
     <Input w='40%'
        type="text"
        variant='ghost'
        bg='#F8FAF1 '
        mt={5}
       
        border='1px solid #E8E8E6'
        placeholder="Search by Name  or Code"
        value={searchTerm1}
        onChange={handleSearchChange1}
        onKeyDown={handleKeyDown1} 
        
         // Adds margin bottom for spacing
      /> 
      
        
      <OrderedList  width='60%'  display='flex' flexDirection='column' justifyContent='center' alignItems='center'  height='fit-content' ref={listRef1} tabIndex={-1}    >
        
      {searchTerm1 !== "" ? wineFilterData.map((item,index)=>(
           <ListItem width='69%' as='i' bg={index === focusedIndex1 ? 'red' : 'transparent'} 
           color={index === focusedIndex1 ? 'white' : 'black'}  p={2} mb={1} borderRadius='6px' key={item._id} onClick={() => handleAddToBill(item)} >{item.name}</ListItem>
      )) : (<></>)}
      </OrderedList> </Box>}
      
    
      
     <Box>
   
    <Box display='flex' flexDirection='row' p={4} >
    
      <Box>
        {selection === 'Food' ?
        <Box display="flex" flexDirection='column' justifyContent="center" marginBottom="20px" gap="10px" >
          <Button onClick={() => handleTypeFilterChange('All')} colorScheme={typeFilter === 'All' ? 'green' : 'gray'}>All</Button>
          <Button onClick={() => handleTypeFilterChange('Veg')} colorScheme={typeFilter === 'Veg' ? 'green' : 'gray'}>Veg</Button>
          <Button onClick={() => handleTypeFilterChange('Non-Veg')} colorScheme={typeFilter === 'Non-Veg' ? 'green' : 'gray'}>Non-Veg</Button>
        </Box> : ""}
        <Grid Grid templateColumns='repeat(1, 1fr)' marginBottom="20px" gap="10px">
          {uniqueNames.map((name, index) => (
            <GridItem  p={1} cursor='pointer' borderRadius='6px' key={index} onClick={() => handleNameFilterChange(name)} as='i' bg={nameFilter === name ?  '#389C2B' : '#E2ECE1'} color={nameFilter === name ? 'white' : 'black'}>
              {name}
            </GridItem>
          ))}
        </Grid>
      </Box>
      <Box>
      <Box display='flex' flexDirection='row' justifyContent='space-evenly' mb={3}> 
      <Text
          onClick={() => handleSelectionChange('Food')}
          cursor='pointer'
          fontWeight={selection === 'Food' ? 'bold' : 'normal'}
          borderBottom={selection=== 'Food' ? '3px solid tomato' : ""}
          color={selection=== 'Food' ? 'tomato' : "black"}
        >
          Food
        </Text>
        <Text
          onClick={() => handleSelectionChange('Wine')}
          cursor='pointer'
          fontWeight={selection === 'Wine' ? 'bold' : 'normal'}
          borderBottom={selection=== 'Wine' ? '3px solid tomato' : ""}
          color={selection=== 'Wine' ? 'tomato' : "black"}
        >
          Wine
        </Text>
        </Box>
      <Box display='flex' width='100%'>
       
        <HalfPlateModal isOpen={isFoodModalOpen} onClose={onCloseFoodModal} onOpen={onOpenFoodModal} isAc={isAc} tableNo={tableNo} data={foodData} />
        <FullBottleModal isOpen={isWineModalOpen} onClose={onCloseWineModal} onOpen={onOpenWineModal} isAc={isAc} tableNo={tableNo} data={foodData1} isBottle={isBottle} />
        <SimpleGrid columns={3} placeItems='center' height='fit-content' gap='10px'  p={2} width='full'>
          {dataToDisplay.map((item, index) => (
            <Card boxShadow='dark-lg' maxW='sm' key={index} height='180px'>
             <Image
                  src={item.imageUrl}
                  alt={item.tableNo}
                  borderRadius='lg'
                  height='full'
                  width='170px'
                />
                <Text bg={item.type === "Non-Veg" ? 'red' : 'green'} p={0.5} opacity={0.8} textAlign='center' position='absolute' top='20%' left='73%' transform='translate(-50%, -50%)' color='white' fontSize='sm' as='b'>Code:{item.code} {item.halfPlate ? <RxHalf2 size='28px' cursor='pointer' onClick={()=>handleToggle(item._id,selection)}/> : ""}
                {item.halfPlate && item.purchaseRate ? <FaWineBottle size='28px' cursor='pointer' onClick={()=>handleToggle1(item._id,selection)}/> : ""}

                </Text>
              <CardBody onClick={() => handleAddToBill(item)} cursor='pointer' textAlign='center' position='absolute' top='70%' left='70%' transform='translate(-70%, -70%)' bg={item.type === "Non-Veg" ? 'red' : 'green'} opacity='0.8' width='full' p={2}>
               <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                <Text fontSize='sm' as='b' color='white' display='flex'>{item.name}</Text>
                <Text fontSize='sm' as='b' color='white' display='flex'> Rate:{isAc ? item.acRate : item.nonAcRate}</Text>
                {/* <Text fontSize='sm' as='b' color='white' display='flex' alignItems='center'>Non-AC Rate:{item.nonAcRate}</Text> */}
                

                </Box>
               
               
                
              </CardBody>
            
             
            </Card>
          ))}
        </SimpleGrid>
      </Box>
      </Box>
      <Box display='flex' flexDirection='column' width='42%' alignItems='center'>
      <Box display='flex' bg='#F8FAF1 ' borderRadius='6px' flexDirection='column' width='full' height='fit-content' boxShadow='dark-lg' >
        
        <Box>
        <Flex flexDirection="row" alignItems="center" gap={4} >  <Select bg='#FAFAFA' p={4} icon={<MdArrowDropDown />} variant='flushed' value={kot} name='kot' onChange={handleKotChange}>
          <option bg='#FAFAFA' p={2} value={'Kot'} name='kot' onChange={handleKotChange}>Order/KOT</option>
          {/* <option bg='#FAFAFA' p={2} value={'bill'}>Bill</option> */}
          <option bg='#FAFAFA' p={2} value={'quick'}>Quick Bill</option>
          </Select>
          </Flex></Box>
          {kot === 'Kot' ?
          <>
          <Box bg='#F74C31' height='100px' display='flex' p={10} borderRadius='4px' justifyContent='space-between' overflowY='hidden' color='white' alignItems='center'>
          <Text fontSize='small'>KOT No-</Text>
          
         <Input width='150px' bg='white' placeholder='Enter tableNo' value={kotData.tableNo} color='black' name='tableNo' onChange={handleKotDataChange}/>
        </Box>
        <Box display='flex' flexDirection='column' p={2} justifyContent='space-evenly' minHeight='250px' overflowY='scroll'>
          
          {foodItems[0]?.items.map((item, index) => (
            <Box key={index} display='flex' justifyContent='space-between' alignItems='center' p={1}>
              <Text fontSize='sm'  name='name'>{item.name}</Text>
             
              
              <ButtonGroup size="md" isAttached variant="ghost">
    <Button colorScheme='red' color='red'
      onClick={() => handleQuantityChange(index, Math.max(0, item.quantity - 1))} 
      isDisabled={item.quantity <= 0}
    >
      <FaMinus/>
    </Button>
    <Input
      type="number"
      value={item.quantity}
      onChange={(e) => handleQuantityChange(index, parseInt(e.target.value) || 0)}
      placeholder="Quantity"
      width="50px"
      textAlign="center"
    />
    <Button colorScheme='red' onClick={() => handleQuantityChange(index, item.quantity + 1)}>
      <FaPlus/>
    </Button>
  </ButtonGroup>
 <Input width='20%'  onChange={(e)=>{changeNote(index,e.target.value)}} value={item.note}/>
 

             
              <Text display='flex' alignItems='center' fontSize='sm'><FaRupeeSign /> {item.price}</Text>
              
             

              <Button  colorScheme='red' onClick={() => handleDeleteItem(index)}><FaTrash /></Button>
            </Box>
          ))}
          </Box>
          <Box bg='#F74C31' height='100px' display='flex' flexDirection='row' p={5} borderRadius='4px' justifyContent='center' gap={4} overflowY='hidden' color='white' alignItems='center'>
        
        
       
        <Button as='b' colorScheme='blue'  onClick={()=>{kotPrint();startTimer();}}>Print And Save</Button>
        <Button as='b' colorScheme='blue'  onClick={kotUpdate}>Update</Button>
        <Button as='b' colorScheme='blue'  onClick={()=>handleClearBill(tableNo)}>Clear</Button>
        
      </Box>
        </> : kot == 'bill' ? <>
        <Box bg='#F74C31' height='100px' display='flex' p={10} borderRadius='4px' justifyContent='space-between' overflowY='hidden' color='white' alignItems='center'>
          
          <Select width='150px' value={select} bg='white' color='black' onChange={handleSelect} mr={2}>
            <option value={"gst"}>Gst</option>
            <option value={'non-gst'}>Non-Gst</option>
          </Select>
         <Input width='150px' bg='white' placeholder='Enter registration' value={formData.registration} name='registration' color='black' onChange={handleInputChange}/>
        </Box>
        <Box display='flex' flexDirection='column' p={2} justifyContent='space-evenly' minHeight='250px' overflowY='scroll'>
       
          <Box  display='flex' justifyContent='space-between' alignItems='center' p={2}>
            
           < TableContainer>
  <Table variant='simple'>
    <TableCaption>Bill Data</TableCaption>
    <Thead>
      <Tr>
        <Th>Item</Th>
        <Th>Quantity</Th>
        <Th >Price</Th>
      </Tr>
    </Thead>
    <Tbody>
    {foodItems[0]?.items.map((item) => (
      <Tr key={item._id}>
        <Td>{item.name}</Td>
        <Td>{item.quantity}</Td>
        <Td>{item.price}</Td>
      </Tr>
    ))}
    </Tbody>
    
  </Table>
</TableContainer>
            
          </Box>
          </Box>
          <Box bg='#F74C31' height='fit-content' display='flex' flexDirection='row' p={5} borderRadius='4px' justifyContent='space-between' overflowY='hidden' color='white' alignItems='center'>
        
          <Text display='flex' alignItems='center' as='b'>Total- <FaRupeeSign mt={2} /> {totalSum}</Text>
         
          <Button as='b' colorScheme='blue' onClick={handleSaveAndPrint}>Submit</Button>
          <Button as='b' colorScheme='blue' onClick={save}>Save</Button>
          
        </Box>
       
      
      </>
       :<>
       <Box bg='#F74C31' height='100px' display='flex' p={10} borderRadius='4px' justifyContent='space-between' overflowY='hidden' color='white' alignItems='center'>
         
         <Select width='40%' value={select} bg='white' color='black' onChange={handleSelect} mr={2}>
           <option value={"gst"}>Gst</option>
           <option value={'non-gst'}>Non-Gst</option>
         </Select>
        {/* <Input width='60%' bg='white' placeholder='Enter registration' value={formData.registration} name='registration' color='black' onChange={handleInputChange}/> */}
       </Box>
       <Box display='flex' flexDirection='column' p={2} justifyContent='space-evenly' minHeight='250px' overflowY='scroll'>
      
         <Box  display='flex' justifyContent='space-between' alignItems='center' p={2}>
           
          < TableContainer>
 <Table variant='simple'>
   <TableCaption>Bill Data</TableCaption>
   <Thead>
     <Tr>
       <Th>Item</Th>
       <Th>Quantity</Th>
       <Th >Price</Th>
     </Tr>
   </Thead>
   <Tbody>
   {arr1?.map((item) => (
     <Tr key={item._id}>
       <Td>{item.name}</Td>
       <Td>{item.quantity}</Td>
       <Td>{item.price}</Td>
     </Tr>
   ))}
   </Tbody>
   
 </Table>
</TableContainer>
           
         </Box>
         </Box>
         <Grid bg='#F74C31' height='fit-content' templateColumns='repeat(2, 1fr)' gap={6}  p={5} borderRadius='4px' placeItems='center' overflowY='hidden' color='white' alignItems='center' >
       
         <Input width='80%' bg='white' placeholder='Name' value={quickBill.name} name='name' color='black' onChange={handleQuickDataChange}/>
         <Input width='100%' bg='white' placeholder=' Phone' value={quickBill.phoneNo} name='phoneNo' color='black' onChange={handleQuickDataChange}/>
         <Input width='100%' bg='white' placeholder=' Cash discount' value={quickBill.discount} name='discount' color='black' onChange={handleQuickDataChange}/>
         <Input width='100%' bg='white' placeholder=' Discount %' value={quickBill.discountPercentage} name='discountPercentage' color='black' onChange={handleQuickDataChange}/>
         <Select width='100%' bg='white' placeholder='select mode'  value={quickBill.mode} name='mode' color='black' onChange={handleQuickDataChange}>
          <option value='Phonepe'>Phonepe</option>
          <option value='GooglePe'>Googlepe</option>
          <option value="Paytm">Paytm</option>
          <option value="Upi">Upi</option>
          <option value='Card'>Card</option>
         </Select>
        
         {quickBill.mode && quickBill.mode !== "Card" ? <Input width='100%' bg='white' placeholder='transaction id' value={quickBill.transactionId}  name='transactionId' color='black' onChange={handleQuickDataChange}/>:""}
         {quickBill.mode  ?<Input width='100%' bg='white' placeholder={quickBill.mode != "Card" ? "Online Amount" : "Card Amount"} value={quickBill.online} onFocus={onLineFoucus} name='online' color='black' onChange={handleQuickDataChange}/>:""}
         {quickBill.mode === "Card" ? <Input width='100%' bg='white' placeholder='Card last 4 digit' value={quickBill.cardNo}  name='cardNo' color='black' onChange={handleQuickDataChange}/>:""}
         {quickBill.mode === "Card" ? <Input width='100%' bg='white' placeholder='Card Bank Name' value={quickBill.bankName}  name='bankName' color='black' onChange={handleQuickDataChange}/>:""}
         <Input width='100%' bg='white' placeholder=' Cash amount' value={quickBill.cash} onFocus={cashFoucus} name='cash' color='black' onChange={handleQuickDataChange}/>
         
       </Grid>
         <Box bg='#F74C31' height='fit-content' display='flex' flexDirection='row' p={5} borderRadius='4px' justifyContent='space-between' overflowY='hidden' color='white' alignItems='center'>
       
         <Text display='flex' alignItems='center' as='b'>Total- <FaRupeeSign mt={2} /> {cashDiscount}</Text>
        
         <Button as='b' colorScheme='blue' onClick={quickSave}>Submit</Button>
         <Button as='b' colorScheme='blue' onClick={print}>Print</Button>
         <Button as='b' colorScheme='blue' onClick={qSave}>Save</Button>
         
       </Box>
      
     
     </> }
        
      </Box>
      {arr[0] ? (
      <Card mt={10} textAlign='center' boxShadow='dark-lg' ref={componentRef}     width='3in' padding='0.2in'  >
        <h4 color='purple' fontSize='18px'  mt={5}>Cash Memo</h4>
        <CardHeader display='flex' flexDirection='column' alignItems='center' gap={2} p={2} boxShadow='md'>
          <Box display='flex' flexDirection='column' alignItems='center' gap={2}>
            {printData.map((item) => (
              <Box key={item.name} textAlign='center' display='flex' flexDirection='column' alignItems='center' justifyContent='center'  >
                <Image src={item.imageUrl} w='90px' h='60px'
                 />
                <h4 ><b>{item.name}</b></h4>
                <Text fontSize='xs'>{item.adress}</Text>
                <Text fontSize='xs'>{item.phoneNo}</Text>
                <Text fontSize='xs'>{item.website}</Text>
               {item.gstNo ? <Text fontSize='xs'>GstIn-{item.gstNo}</Text>:""}
               {item.fssaiNo? <Text fontSize='xs'>FssaiNo-{item.fssaiNo}</Text>:""}
              </Box>
            ))}
          </Box>
        </CardHeader>
        <Box p={2} textAlign='center' display='flex' flexDirection='column' alignItems='center' justifyContent='center' gap={2}>
          {/* <Text>Name: {quickBill.name}</Text>
          <Text>Phone: {quickBill.phoneNo}</Text> */}
          <Box display='flex' flexDirection='row' alignItems='center' gap={3}  justifyContent='space-evenly'  >
          <Text fontSize='12px'>Table: {quickBill.tableNo}</Text>
          <Text fontSize='12px'>Bill: {quickBill.billNo}</Text>
          </Box>
          <Box display='flex' flexDirection='row'   alignItems='center' gap={3} justifyContent='space-evenly'  >
          <Text fontSize='12px'>Date: {newDate[1]}</Text>
          <Text fontSize='12px'>Time: {newDate[2]}</Text>
          </Box>
         {serverName ? <Text fontSize='12px'>Server-{serverName}</Text>:""}
        </Box>
        {wineItems && wineItems?.length > 0 ?<>  <Text as='b' mb={2}># Wine Items #</Text>   <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '11px',marginBottom:'2px',textAlign:'left',padding:'5px' }}>
            {/* <TableCaption>Wine Data</TableCaption> */}
            <thead>
              <tr>
               
                <th fontSize='10px' style={{borderBottom:"1px dotted black"}} >Items Description</th>
                <th fontSize='10px' style={{borderBottom:"1px dotted black"}} >Rate</th>
                <th fontSize='10px' style={{borderBottom:"1px dotted black"}} >Qty</th>
                <th fontSize='10px' style={{borderBottom:"1px dotted black"}} >Unit</th>
                <th fontSize='10px' style={{borderBottom:"1px dotted black"}} >Amount</th>
              </tr>
            </thead>
            <tbody>
              {arr2?.map((item, index) => (
                <tr key={index}>
                  {/* <td fontSize='9px' style={{borderBottom:"1px dotted black"}}>{index+1}</td > */}
                  <td fontSize='9px' style={{borderBottom:"1px dotted black"}}>{item.name}</td>
                  <td fontSize='9px' style={{borderBottom:"1px dotted black"}}>{item.price/item.quantity}</td>
                  <td fontSize='9px' style={{borderBottom:"1px dotted black"}}>{item.quantity}</td>
                  <td fontSize='9px' style={{borderBottom:"1px dotted black"}}>{item.unit}</td>
                  <td fontSize='9px' style={{borderBottom:"1px dotted black"}}>{item.price}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
  <tr>
    <td colSpan={5} style={{ textAlign: 'right', fontSize: '12px', borderBottom: "1px dotted black",paddingRight:"15px" }}>
     Gross Total: {arr2.reduce((acc, item) => acc + item.price, 0)}
    </td>
  </tr>
  <tr>
    <td colSpan={5} style={{ textAlign: 'right', fontSize: '12px', borderBottom: "1px dotted black",paddingRight:"15px" }}>
     Drinks Total: {arr2.reduce((acc, item) => acc + item.price, 0)}
    </td>

  </tr>
  </tfoot>
          </table> </>: ""}

        <Text as='b' mb={2}> # Food Items #</Text>
       
          <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '11px',textAlign:'left',marginBottom:'2px',padding:'5px' }}>
            {/* <TableCaption>Food Data</TableCaption> */}
            <thead>
              <tr>
                
                <th fontSize='10px' style={{borderBottom:"1px dotted black"}} >Items Description</th>
                <th fontSize='10px' style={{borderBottom:"1px dotted black"}} >Rate</th>
                <th fontSize='10px' style={{borderBottom:"1px dotted black"}} >Qty</th>
               
                <th fontSize='10px' style={{borderBottom:"1px dotted black"}} >Amount</th>
              </tr>
            </thead>
            <tbody>
              {arr3?.map((item, index) => (
                <tr key={index}>
                  
                  <td fontSize='9px' style={{borderBottom:"1px dotted black"}}>{item.name}</td>
                  <td fontSize='9px' style={{borderBottom:"1px dotted black"}}>{item.price/item.quantity}</td>
                  <td fontSize='9px' style={{borderBottom:"1px dotted black"}}>{item.quantity}</td>
                
                  <td fontSize='9px' style={{borderBottom:"1px dotted black"}}>{item.price}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
            <tr>
    <td colSpan={5} style={{ textAlign: 'right', fontSize: '12px', borderBottom: "1px dotted black",paddingRight:"15px" }}>
     Gross Total: {taxableAmount}
    </td>
  </tr>
  <tr>
    <td colSpan={5} style={{ textAlign: 'right', fontSize: '12px', borderBottom: "1px dotted black",paddingRight:"15px" }}>
     Add @ 2.5% CGST on Gross: {rsgst}
    </td>
  </tr>
  <tr>
  
    <td colSpan={5} style={{ textAlign: 'right', fontSize: '12px', borderBottom: "1px dotted black",paddingRight:"15px" }}>
     Add @ 2.5% SGST on Gross: {rcgst}
    </td>
  </tr>
  <tr>
  
    <td colSpan={5} style={{ textAlign: 'right', fontSize: '12px', borderBottom: "1px dotted black",paddingRight:"15px" }}>
      5% Total GST: {rcgst*2}
    </td>
  </tr>
  
  <tr>
    <td colSpan={5} style={{ textAlign: 'right', fontSize: '12px', borderBottom: "1px dotted black",paddingRight:"15px" }}>
     Food Total: {taxableAmount+rcgst+rsgst}
    </td>
  </tr>
  </tfoot>
          </table>
       
       
    
          <Box display='flex' flexDirection='column' p={2} textAlign='center' borderBottom='1px dotted black'>
          {printData.map((item) => (
            <>
            <Text key={item.name} as='b' fontSize='14px' textAlign='right' >
              {!quickBill.discount || !quickBill.discountPercentage ? "Net":"" } Gross Total Amount- {totalSum-(rcgst+rsgst)} 
            </Text>
            <Text key={item.name} as='b' fontSize='14px' textAlign='right' >
               5% GST Total- {rcgst+rsgst} 
            </Text>
            <Text key={item.name} as='b' fontSize='14px' textAlign='right' >
               Total Amount- {totalSum} 
            </Text>
            <Text fontSize='12px' textAlign='right' >{quickBill.discount? `Cash Discount ${quickBill.discount}`:""}</Text>
            <Text fontSize='12px' textAlign='right'>{quickBill.discountPercentage? `Discount% ${quickBill.discountPercentage}`:""}</Text>
           {quickBill.discount || quickBill.discountPercentage ? <Text key={item.name} as='b' fontSize='14px' textAlign='right' >
              Net Total- {cashDiscount.toFixed(2)} 
            </Text>:""}
            </>
          ))}
        </Box>
       
        <Box display='flex' flexDirection='column' p={2} textAlign='left' borderBottom='1px dotted black'>
          {printData.map((item) => (
            <>
            
            <Text key={item.name} as='b' fontSize='10px' textAlign='left'>
              Total Amount (INR - In Words): {totalSum ? numberToWords(cashDiscount) : 'Zero'}
            </Text>
            </>
          ))}
        </Box>
        {/* <Box display='flex' alignItems='center' justifyContent='center' textAlign='left' flexDirection='row' p={2}>
          <Box display='flex' flexDirection='column' border='1px solid black' p={1} fontSize='12px'>
            <Text borderBottom='1px solid black' bg='yellow.200' fontSize='12px'>Summary</Text>
            <Text fontSize='12px'>Total Rate</Text>
         {wineItems && wineItems.length>0 ?   <Text fontSize='12px'>Wine Rate</Text> : ""}
            
            <Text fontSize='12px'>Food Rate</Text>
           
            <Text fontSize='12px'>Food CGST</Text>
            <Text fontSize='12px'>Food SGST</Text>
            <Text fontSize='12px'>Grand Total</Text>
          </Box>
          <Box display='flex' flexDirection='column' textAlign='left' alignItems='left' justifyContent='left' border='1px solid black' p={1}>
            <Text borderBottom='1px solid black' bg='gray.200' fontSize='12px'>Amount</Text>
            <Text fontSize='12px'>{totalAmount}</Text>
           {wineItems && wineItems.length > 0 ? <Text fontSize='12px'>{wineAmount}</Text> :""}
            <Text fontSize='12px'>{taxableAmount}</Text>
            <Text fontSize='12px'>{rcgst}</Text>
            <Text fontSize='12px'>{rsgst}</Text>
            <Text fontSize='12px' borderTop='1px solid black'>{totalSum}</Text>
          </Box>
          
        </Box> */}
        <Box display='flex' textAlign='center' justifyContent='center' alignItems='center' mb={2} gap='1'>
        {quickBill.cash? <Text as='b' display='flex'  justifyContent='center' alignItems='center' fontFamily='sans-serif'>Cash -<FaRupeeSign/> {quickBill.cash}</Text>: ""}
          {quickBill.online? <Text as='b' display='flex'  justifyContent='center' alignItems='center' fontFamily='sans-serif'>{quickBill.mode} - <FaRupeeSign/>{quickBill.online}</Text>: ""}
          </Box>
        <CardFooter display='flex' flexDirection='column' alignItems='center' p={2} textAlign='center'>
          {/* <h4 ><b>Cash is not refundable</b></h4> */}
          <Text as='b'>Thank You</Text>
          <Text as='b'>Visit again</Text>
        </CardFooter>
      </Card>
    ) : ''}
      </Box>
      
      
        </Box>
        </Box>
        <Box>
          
      
   {/* {arr[0] ? <Button type="submit" variant='solid' color='#0F172B' bg='#FEA116' _hover={{color:'white'}} mt={5} onClick={handlePrint}>Print</Button> : ""}  */}
   </Box>
      
    </Box>
  );
}

export default RestaurentData;