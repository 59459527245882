// Chart.js
import React, { useState,useEffect } from 'react';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';


const Chart = () => {
  const [bill,setbill]=useState([]);
  const customerId=localStorage.getItem('customerId')
  const billData = async () => {
    const response = await axios.get(`https://api.rcb.tdrsoftware.in/api/getQuickBillData/${customerId}`);
    setbill(response.data.quickBill);
  };
  let groupedByDate = {};
  bill.forEach((booking) => {
    //let date=booking.date;
      let dates = booking.createdAt;
    let s=new Date(dates);
    const date=s.toISOString().split('T')[0]
   
      if (!groupedByDate[date]) {
          groupedByDate[date] = [];
      }
      groupedByDate[date].push(booking);
  });
  
  // Step 2: Calculate total bill for each date
  let totalBillsByDate = {};
  Object.keys(groupedByDate).forEach((date) => {
      let total = groupedByDate[date].reduce((acc, booking) => acc + parseInt(booking.totalBilling), 0);
      totalBillsByDate[date] = total;
  });
  
  // // Step 3: Display or use totalBillsByDate as needed
  console.log("Total bills by date:", totalBillsByDate);
  
  function addValuesByMonth(data) {
    let totalsByMonth = {};
  
    // Iterate through each key-value pair in the object
    for (let date in data) {
        if (data.hasOwnProperty(date)) {
            let [year, month] = date.split('-');
            let monthYearKey = `${year}-${month}`;
  
            // Initialize total for the month if it doesn't exist
            if (!totalsByMonth[monthYearKey]) {
                totalsByMonth[monthYearKey] = 0;
            }
  
            // Add the value to the total for the corresponding month
            totalsByMonth[monthYearKey] += data[date];
        }
    }
  
    return totalsByMonth;
  }
  
  // Calculate totals by month
  let totalsByMonth = addValuesByMonth(totalBillsByDate);
  console.log("Totals by month:", totalsByMonth);
  useEffect(()=>{
   billData()
  },[customerId])
  const series = [{
    name: 'Sales',
    data: Object.values(totalsByMonth)
  }];
  const options = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    xaxis: {
      categories: Object.keys(totalsByMonth)}
    }

  return (
    <ReactApexChart options={options} series={series} type="line" height={250}   />
  );
};

export default Chart;
