import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Select,
    Box,
    Flex,Image,
    Text,Button,Card,CardBody,SimpleGrid,
    Textarea,
    TableContainer,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
  } from "@chakra-ui/react";
  import axios from 'axios';
  import {useSelector} from 'react-redux'
  
  import "react-calendar/dist/Calendar.css";
  import Calendar from "react-calendar";
  import React, { useState,useEffect,useRef } from "react";

  
   
    
  import toast from "react-hot-toast";
import { set } from "lodash";
   
    
   
    
  
    const WaiterRegister = () => {
      const customerId=localStorage.getItem('customerId');
      const [waiter,setWaiter]=useState([]);
      const [isEditing,setIsEditing]=useState(false);
      const [editWaiterId,setEditWaiterId]=useState(null);
      const [isOpen, setIsOpen] = useState(false);
      const [deleteItemId, setDeleteItemId] = useState(null);
      const cancelRef = useRef();
    console.log(customerId);
   
    
  const waiterData=async()=>{
    try {
        const response = await axios.get(`https://api.rcb.tdrsoftware.in/api/waiterList/${customerId}`);
        setWaiter(response.data.waiterList);

    } catch (error) {
      //  toast.error(error.message);
        
    }
  }
  
   
  
     
     
      const [formData, setFormData] = useState({
       name:"",
       password:"",
       designation:"",
       address:'',
       phoneNo:''
        
        
      });
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
      const validateForm = () => {
        const requiredFields = [
          "name",
          "password",
          "designation",
          "address",
          "phoneNo",
         
          
          
        ];
    
        for (const field of requiredFields) {
          if (!formData[field]) {
            return false;
          }
        }
        return true;
      };
      const openModal = (id) => {
        setDeleteItemId(id);
        setIsOpen(true);
      };
    
      const onClose = () => {
        setIsOpen(false);
        setDeleteItemId(null);
      };
      const handleDelete=async()=>{
       
        try {
          const response=await axios.delete(`https://api.rcb.tdrsoftware.in/api/waiterDelete/${customerId}/${deleteItemId}`)
          toast.success(response.data.message)
          setIsOpen(false)
          setDeleteItemId(null)
          waiterData()
        } catch (error) {
          toast.error(error.message)
        }
      }
      const handleSubmit =async (e) => {
        e.preventDefault();
        if (!validateForm()) {
          toast.error("Please fill in all required fields.");
          return;
        }
        // Here you can send formData to your backend server or process it further
       
        // Reset form after submission
        
        setFormData({
            name:formData.name,
            phoneNo:formData.phoneNo,
            password:formData.password,
            address:formData.address,
            designation:formData.designation
        
        
        
         
        });
     
        
      };
      const edit=(item)=>{
        setIsEditing(true);
        setEditWaiterId(item._id);
       
        
          setFormData({
            name: item.name,
            phoneNo:item.phoneNo,
            password: item.password,
            address: item.address,
            designation: item.designation,
          })
      
    }
      useEffect(()=>{
        waiterData();
      },[customerId])
  
   
  
      const save=async(e)=>{
        if (!validateForm()) {
          toast.error("Please fill in all required fields.");
          return;
        }
        e.preventDefault()
        if(isEditing){
            try {
                const response=await axios.put(`https://api.rcb.tdrsoftware.in/api/waiterUpdate/${customerId}/${editWaiterId}`,{
                  
                  data:formData
                });
                toast.success(response.data.message);
                setIsEditing(false);
                setEditWaiterId(null);
                setFormData({
                    name:"",
                    phoneNo:"",
                    password:"",
                    address:'',
                    designation:'',
                    image:null
                  })
                window.location.reload();
            } catch (error) {
                toast.error("Failed to update waiter. Please try again later.");
            }
        }else{
         // Prepare form data for sending to backend
     
        try{
          const response=await axios.post("https://api.rcb.tdrsoftware.in/api/waiterRegister",{
            customerId:customerId,data:formData
          });
          console.log('Data Submitted: ',response.data);
          toast.success(response.data.message);
          setFormData({
            name:"",
            phoneNo:"",
            password:"",
            address:'',
            designation:'',
            image:null
          })
          
          window.location.reload();
        
           
      }catch(error){
       
          console.error("Error:", error);
          toast.error("Failed to bill room. Please try again later.");
        
      }
    }
    
    }
 
      return (
        <Box   bg='#f7f7f7 '
        columns={{ sm: 2, md: 4 }}
        spacing='8'
        p='10'
        textAlign='center'
        
        color='black' width='100%' height='fit-content'   >
          
          <FormControl isRequired onSubmit={handleSubmit}>
            <Box display="grid"  gridGap={1} >
              <Flex
                display="flex"
                gap="10px"
                alignItems="center"
                justifyContent="space-evenly"
               
              >
                <Box width="40%"  display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                
                p={4}>
                  <FormLabel  >Name</FormLabel>
                  <Input w='70%' bg='#FAFAFA ' placeholder="Enter Waiter Name" border='1px solid #ffe4d9'   name='name' value={formData.name} onChange={handleInputChange} />
                </Box>
                <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Password</FormLabel>
                  <Input w='70%'  bg='#FAFAFA '  placeholder="Enter Password" border='1px solid #ffe4d9' name='password' value={formData.password} onChange={handleInputChange} />  
                   
                </Box>
              </Flex>
              <Box
                display="flex"
                gap="10px"
                alignItems="center"
                justifyContent="space-evenly"
                
              >
                <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Designation</FormLabel>
                  <Input w='70%' bg='#FAFAFA ' placeholder="Enter Designation " name='designation' value={formData.designation} onChange={handleInputChange}/>
                
                </Box>
                <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>PhoneNo</FormLabel>
                  <Input w='70%' bg='#FAFAFA ' placeholder="Enter PhoneNo" name='phoneNo' value={formData.phoneNo} onChange={handleInputChange}/>
       
                  
                </Box>
                <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
                borderRadius="5px"
                p={4}>
                  <FormLabel>Address</FormLabel>
                  <Input w='70%' bg='#FAFAFA ' placeholder="Enter Address" name='address'  value={formData.address} onChange={handleInputChange}/>
       
                  
                </Box>
                
              </Box>
              
              
            
              
              
             
                
               
             
             
             
              
              
              
            <hr/>
            <Box display='flex' flexDirection='column' rowGap='10px' mt={4}>
           
                </Box>
  
            </Box>
           
         
            <Button ac="submit" variant='solid' color='white' bg='tomato' _hover={{color:'tomato',bg:"white"}} mt={5} onClick={save}>{isEditing ? "Update":"Save"}</Button>
          </FormControl>
          <TableContainer width='full' mt={10} border='1px solid black' p={4} bg='white' borderRadius='10px' fontSize='sm'>
                <Table size='sm' variant='striped' colorScheme='teal'>
                    <TableCaption>Item Data</TableCaption>
                    <Thead>
                        <Tr >
                            <Th >Name</Th>
                            <Th>designation</Th>
                            <Th>Phone No</Th>
                            <Th>Password</Th>
                            <Th>Address</Th>
                            
                            
                            <Th>Edit</Th>
                            <Th>Delete</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {waiter.map((item, index) => (
                            <Tr key={index}>
                                <Td>{item.name}</Td>
                                <Td>{item.designation}</Td>
                                <Td>{item.phoneNo}</Td>
                                <Td>{item.password}</Td>
                                <Td>{item.address}</Td>
                               
                                
                                
                                <Td><Button colorScheme="green" size="sm" onClick={() => edit(item)}>Edit</Button></Td>
                                <Td><Button colorScheme="red" size="sm" onClick={() => openModal(item._id)}>Delete</Button></Td>

                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Deletion
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure you want to delete this item?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      
          
        </Box>
      );
  
    
  
    }
  
  
  
  
  
  export default WaiterRegister